import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CopyButton,
  Form,
  FormControl,
  Note,
  TextInput,
} from "@contentful/f36-components";

import ASSET_TYPE from "./common";

const Caption = ({
  isLoading,
  assetMeta,
  onButtonClick,
  updateFieldOnClear,
}) => {
  if (
    !assetMeta ||
    (assetMeta.type !== ASSET_TYPE.VIDEO && !assetMeta.caption)
  ) {
    return <></>;
  }

  let isCaptionPublished = false;
  let isCaptionSelected = true;
  if (assetMeta && assetMeta.type === ASSET_TYPE.VIDEO && !assetMeta.caption) {
    isCaptionSelected = false;
  }
  if (assetMeta && assetMeta.caption && assetMeta.caption.captionUrl) {
    isCaptionPublished = true;
  }

  return (
    <>
      {!isCaptionSelected && (
        <div className="asset-details">
          <br />
          <Note variant="warning" className="note">
            The caption does not appear to be selected. Please select a valid
            caption for this video asset in the DAM.
          </Note>
        </div>
      )}

      {isCaptionSelected && !isCaptionPublished && (
        <div className="asset-details">
          <br />
          <Note variant="warning" className="note">
            The caption does not appear to be published. Please publish the
            caption for this caption asset in the DAM.
          </Note>
          <Form className="unpublished-asset-form">
            <FormControl id="contentPath">
              <FormControl.Label>Caption Content Path</FormControl.Label>
              <TextInput.Group>
                <TextInput
                  name="contentPath"
                  value={assetMeta.caption ? assetMeta.caption.contentPath : ""}
                  isDisabled
                />
                <CopyButton />
              </TextInput.Group>
            </FormControl>
          </Form>
        </div>
      )}

      {isCaptionSelected && isCaptionPublished && (
        <div className="asset-details">
          <br />
          <Form>
            <FormControl id="url">
              <FormControl.Label>Caption URL</FormControl.Label>
              <TextInput.Group>
                <TextInput
                  name="url"
                  value={assetMeta.caption.captionUrl}
                  isDisabled
                />
                <CopyButton />
              </TextInput.Group>
            </FormControl>
          </Form>
        </div>
      )}

      <Button
        variant="primary"
        isFullWidth={false}
        onClick={() => onButtonClick()}
        isDisabled={isLoading}
        className="action-button"
        data-test-name="select-asset-button"
      >
        Select Caption from DAM
      </Button>
      <Button
        variant="negative"
        isFullWidth={false}
        onClick={() => updateFieldOnClear()}
        isDisabled={!assetMeta.caption || isLoading}
        className="action-button"
      >
        Remove Caption
      </Button>
    </>
  );
};

Caption.defaultProps = {
  assetMeta: undefined,
};

Caption.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  updateFieldOnClear: PropTypes.func.isRequired,
  assetMeta: PropTypes.objectOf(PropTypes.any),
};

export default Caption;
