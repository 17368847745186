import React from "react";
import { Paragraph, Icon } from "@contentful/f36-components";
import { CheckCircleIcon, WarningIcon } from "@contentful/f36-icons";
import PropTypes from "prop-types";
import { assoc, curry } from "ramda";

import ASSET_TYPE from "../AssetFieldEditor/common";

const ValidationEditor = ({ sdk }) => {
  const { isV2Enabled } = sdk.parameters.installation;

  const [locales] = React.useState(sdk.locales.available);
  const [assets, setAssets] = React.useState({});
  const [assetMetas, setAssetMetas] = React.useState({});
  const [altText, setAltText] = React.useState({});
  const [isAltTextRequired, setIsAltTextRequired] = React.useState(() => {
    const optionsField = sdk.entry.fields.isAltTextRequired;
    return typeof optionsField.getValue() !== "undefined"
      ? optionsField.getValue()
      : true;
  });
  const [isValidByLocale, setIsValidByLocale] = React.useState({});
  const [isCaptionValidByLocale, setIsCaptionValidByLocale] = React.useState(
    {}
  );
  const [isAltTextValid, setIsAltTextValid] = React.useState({});

  React.useEffect(() => {
    const assetsToSet = {};
    const assetMetasToSet = {};
    const altTextToSet = {};

    locales.forEach((locale) => {
      assetsToSet[locale] = sdk.entry.fields.asset.getValue(locale);
      assetMetasToSet[locale] = sdk.entry.fields.assetMeta.getValue(locale);
      altTextToSet[locale] = sdk.entry.fields.altText.getValue(locale);
    });

    setAssets(assetsToSet);
    setAssetMetas(assetMetasToSet);
    setAltText(altTextToSet);
  }, [locales, sdk]);

  React.useEffect(() => {
    const unsubscribeAltTextReq = sdk.entry.fields.isAltTextRequired.onValueChanged(
      setIsAltTextRequired
    );

    const setFieldLocale = curry((setFn, locale, newValue) => {
      setFn(assoc(locale, newValue));
    });

    const unsubFns = [];
    locales.forEach((locale) => {
      unsubFns.push(
        sdk.entry.fields.altText.onValueChanged(
          locale,
          setFieldLocale(setAltText, locale)
        )
      );
      unsubFns.push(
        sdk.entry.fields.asset.onValueChanged(
          locale,
          setFieldLocale(setAssets, locale)
        )
      );
      unsubFns.push(
        sdk.entry.fields.assetMeta.onValueChanged(
          locale,
          setFieldLocale(setAssetMetas, locale)
        )
      );
    });

    return () => {
      unsubscribeAltTextReq();

      for (let n = 0; n < unsubFns.length; n += 1) {
        unsubFns[n]();
      }
    };
  }, [sdk, locales]);

  const isVideoCaptionMissing = (asset) => {
    if (!asset) return false;

    if (asset.type === ASSET_TYPE.VIDEO) {
      return !asset.captionUrl;
    }
    return false;
  };

  React.useEffect(() => {
    const newIsValidByLocale = {};
    const newIsCaptionValidByLocale = {};
    const newIsAltTextValid = {};

    let valid = true;
    locales.forEach((locale) => {
      let areAssetsPublished;
      if (isV2Enabled) {
        areAssetsPublished =
          assets[locale] &&
          Array.isArray(assets[locale]) &&
          assets[locale].length > 0 &&
          assets[locale].reduce((areAllAssetsPublished, asset) => {
            if (!areAllAssetsPublished) return areAllAssetsPublished;

            return areAllAssetsPublished && asset.isPublished;
          }, true);
      }

      if (locale === sdk.locales.default) {
        if (isV2Enabled) {
          newIsValidByLocale[locale] = areAssetsPublished;
        } else {
          newIsValidByLocale[locale] = !!(assetMetas[locale] && assets[locale]);
        }

        newIsCaptionValidByLocale[locale] = !isVideoCaptionMissing(
          assets[locale]
        );
      } else {
        if (isV2Enabled) {
          const hasAssets =
            assets[locale] &&
            Array.isArray(assets[locale]) &&
            assets[locale].length > 0;
          // non-default locales only need to be published if they actually have assets
          newIsValidByLocale[locale] = areAssetsPublished || !hasAssets;
        } else {
          newIsValidByLocale[locale] = !!(
            !assetMetas[locale] ||
            (assetMetas[locale] && assets[locale])
          );
        }

        newIsCaptionValidByLocale[locale] =
          assetMetas[locale] === undefined ||
          (assetMetas[locale] !== undefined &&
            assets[locale] !== undefined &&
            !isVideoCaptionMissing(assets[locale]));
      }

      newIsAltTextValid[locale] = true;
      if (
        isAltTextRequired &&
        (!altText[locale] || altText[locale].length < 1)
      ) {
        newIsAltTextValid[locale] = false;
      }

      if (
        !newIsValidByLocale[locale] ||
        !newIsCaptionValidByLocale[locale] ||
        !newIsAltTextValid[locale]
      ) {
        valid = false;
      }
    });

    if (valid) {
      sdk.field.setValue(true);
    } else {
      sdk.field.removeValue();
    }

    setIsValidByLocale(newIsValidByLocale);
    setIsCaptionValidByLocale(newIsCaptionValidByLocale);
    setIsAltTextValid(newIsAltTextValid);
  }, [
    sdk,
    assets,
    assetMetas,
    isAltTextRequired,
    altText,
    locales,
    isV2Enabled,
  ]);

  return (
    <div>
      {locales.map((locale) => {
        const isDefault = locale === sdk.locales.default;

        const assetTextMsg = {};
        if (isDefault) {
          assetTextMsg[locale] =
            "This is the default locale and a published asset is required.";
        } else {
          assetTextMsg[locale] =
            "An asset is not required. If an asset is selected, it must be published.";
        }

        const captionTextMsg = {};
        captionTextMsg[locale] = "";
        if (!isCaptionValidByLocale[locale]) {
          if (isDefault) {
            captionTextMsg[locale] +=
              "A video caption asset must be published and selected for the default locale.";
          } else {
            captionTextMsg[locale] =
              "If a video asset is selected, a video caption asset must be published and selected.";
          }
        }

        const altTextMsg = {};
        altTextMsg[locale] = "";
        if (!isAltTextValid[locale]) {
          altTextMsg[locale] =
            "Alt Text error: when Alt Text is required, Alt Text can not be empty!";
        }

        return (
          <div className="validation-message" key={locale}>
            <Icon
              variant={
                isValidByLocale[locale] &&
                isCaptionValidByLocale[locale] &&
                isAltTextValid[locale]
                  ? "positive"
                  : "negative"
              }
              as={
                isValidByLocale[locale] &&
                isCaptionValidByLocale[locale] &&
                isAltTextValid[locale]
                  ? CheckCircleIcon
                  : WarningIcon
              }
            />
            <Paragraph
              as="span"
              className={`validation-indicator validation-indicator${
                isValidByLocale[locale] &&
                isCaptionValidByLocale[locale] &&
                isAltTextValid[locale]
                  ? "--valid"
                  : ""
              }`}
            >
              {locale}: {assetTextMsg[locale]}
              {!isCaptionValidByLocale[locale] && <br />}
              {"       "}
              {captionTextMsg[locale]}
              <br />
              {"       "}
              {altTextMsg[locale]}
            </Paragraph>
          </div>
        );
      })}
    </div>
  );
};

ValidationEditor.propTypes = {
  sdk: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ValidationEditor;
