import { getSignedHeaders } from "./contentful-cma";

// eslint-disable-next-line import/prefer-default-export
const fetchAssetMetadata = async (sdk, config, contentPath) => {
  let result;

  try {
    // Encode the asset filename, added to ensure plus symbols (+) are handled properly as the netlify function was treating them as spaces ( )
    const lastIndex = contentPath.lastIndexOf("/");
    const encodedContent =
      contentPath.substring(0, lastIndex + 1) +
      encodeURIComponent(contentPath.substring(lastIndex + 1));

    const req = {
      method: "GET",
      url: `${config.metadataPath}?contentPath=${encodedContent}`,
      headers: {},
    };

    const path = new URL(req.url).pathname;

    const additionalHeaders = await getSignedHeaders(sdk, {
      method: req.method,
      headers: req.headers,
      path,
    });

    Object.assign(req.headers, additionalHeaders);

    result = await fetch(req.url, req);
  } catch (e) {
    console.log("Fetching error: ", e);
    return undefined;
  }

  const resultForText = result.clone();
  try {
    result = await result.json();
  } catch (e) {
    const textResult = await resultForText.text();
    console.error(
      "Invalid JSON from activity endpoint. Text response is",
      textResult
    );
    return undefined;
  }

  return result;
};

const fetchFolderMetadata = async (sdk, config, contentPath) => {
  let result;

  try {
    // Encode the asset filename, added to ensure plus symbols (+) are handled properly as the netlify function was treating them as spaces ( )
    const lastIndex = contentPath.lastIndexOf("/");
    const encodedContent =
      contentPath.substring(0, lastIndex + 1) +
      encodeURIComponent(contentPath.substring(lastIndex + 1));

    const req = {
      method: "GET",
      url: `${config.folderMetadataPath}?contentPath=${encodedContent}`,
      headers: {},
    };

    const path = new URL(req.url).pathname;

    const additionalHeaders = await getSignedHeaders(sdk, {
      method: req.method,
      headers: req.headers,
      path,
    });

    Object.assign(req.headers, additionalHeaders);

    result = await fetch(req.url, req);
  } catch (e) {
    console.log("Fetching error: ", e);
    return undefined;
  }

  const resultForText = result.clone();
  try {
    result = await result.json();
  } catch (e) {
    const textResult = await resultForText.text();
    console.error(
      "Invalid JSON from activity endpoint. Text response is",
      textResult
    );
    return undefined;
  }

  return result;
};

export { fetchAssetMetadata, fetchFolderMetadata };
