/**
 * Creates a config object for the SFMC Sync Webhook.
 * Only includes headers if their associated values are non-null.
 * This is done to maintain existing header values when updating an already existing webhook.
 *
 * @param {string} config - The config object for the app.
 * @param {object} sfmcAccountKey - The account key of the SFMC Business Unit to sync to.
 * @param {string} sfmcClientId - The client ID for the SFMC authentication.
 * @param {string} sfmcClientSecret - The client secret for the SFMC authentication.
 * @param {object} deliveryToken - A Contentful Delivery access token.
 *
 * @returns {object} - The built webhook config.
 */
const formatDamWebhookConfig = (config, token) => {
  const webhookConfig = {
    webhookId: config.contentTypeId,
    name: `${config.appName} - App Managed - DO NOT DELETE`,
    url: `https://${config.webhookPath}`,
    topics: ["Entry.publish", "Entry.unpublish"],
    filters: [
      { equals: [{ doc: "sys.contentType.sys.id" }, config.contentTypeId] },
    ],
    headers: [
      {
        key: "Authorization",
        secret: true,
        value: `Bearer ${token}`,
      },
    ],
    transformation: null,
  };

  return webhookConfig;
};

export default formatDamWebhookConfig;
