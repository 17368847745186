import {
  createOrUpdateContentTypes,
  createOrUpdateWebhooks,
  getSpaceEnvironment,
} from "@contentful-platform/contentful-app-helpers";

import formatDamWebhookConfig from "./webhook-utils";

/**
 * Create webhook and content type for the DAM integration
 * @param {Object} config deployment config object
 * @param {string} spaceId Space ID to install webhook and content type in
 * @param {string} environmentId Environment to install webhook and content type in
 * @param {string} accessToken Personal Access Token with permissions for space and environment
 */
// eslint-disable-next-line import/prefer-default-export
export async function onConfigure(
  config,
  spaceId,
  environmentId,
  accessToken,
  isV2Enabled,
  damStartFolder
) {
  const spaceEnvironment = await getSpaceEnvironment(
    spaceId,
    environmentId,
    accessToken
  );
  const { environment, space } = spaceEnvironment;

  await createOrUpdateContentTypes(environment, [config.damConfig]);
  await createOrUpdateWebhooks(space, [
    formatDamWebhookConfig(config, accessToken),
  ]);

  let editorInterface;
  if (isV2Enabled) {
    editorInterface = {
      [config.damConfig.contentTypeId]: {
        editor: true,
      },
    };
  } else {
    editorInterface = {
      [config.contentTypeId]: {
        controls: [
          // TODO use constants for field names
          {
            fieldId: "asset",
          },
          {
            fieldId: "validation",
          },
          {
            fieldId: "isAltTextRequired",
          },
        ],
      },
    };
  }

  return {
    parameters: {
      isV2Enabled,
      damStartFolder,
    },
    // assign the app as the editor interface for the content type created
    targetState: {
      EditorInterface: editorInterface,
    },
  };
}
