const getDamConfig = (config) => {
  return {
    contentTypeId: config.contentTypeId,
    name: config.contentTypeName,
    description: "Used to refer assets from DAM source",
    displayField: "title",
    fields: [
      {
        id: "title",
        name: "Title",
        required: true,
        localized: false,
        type: "Symbol",
      },
      {
        id: "isAltTextRequired",
        name: "Is Alt Text Required",
        type: "Boolean",
        localized: false,
        required: true,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: "altText",
        name: "Alt Text",
        required: false,
        localized: true,
        type: "Symbol",
        disabled: true,
        omitted: false,
      },
      {
        id: "asset",
        name: "DAM Asset",
        required: false,
        localized: true,
        type: "Object",
      },
      {
        id: "assetMeta",
        name: "Asset Meta",
        type: "Object",
        localized: true,
        required: false,
        validations: [],
        disabled: true,
        omitted: true,
      },
      {
        id: "validation",
        name: "Asset Validation",
        type: "Boolean",
        localized: false,
        required: true,
        validations: [],
        disabled: false,
        omitted: true,
      },
    ],
  };
};

export default getDamConfig;
