import React from "react";
import PropTypes from "prop-types";
import { Flex } from "@contentful/f36-components";

import AssetRenderer from "./AssetRenderer";
import DeviceIcons from "./DeviceIcons";
import { doesAssetContainDevice } from "./utils";

const RenditionSelector = ({
  assetInfo,
  renditionIndexToShow,
  setRenditionIndexToShow,
}) => {
  const thumbnails = assetInfo.renditions.map((rendition, index) => {
    const renditionAsset = {
      ...assetInfo,
      url: rendition.url,
    };

    return (
      <button
        type="button"
        className={`thumbnailButton ${
          index === renditionIndexToShow ? "selected" : ""
        }`}
        onClick={() => setRenditionIndexToShow(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        <div style={{ position: "relative" }}>
          <AssetRenderer assetInfo={renditionAsset} />
          <Flex gap="spacing2Xs" className="rendition-device-icons-container">
            <DeviceIcons
              containerClass="rendition-device-icon"
              iconClass="rendition-react-icons"
              doesAssetContainDevice={doesAssetContainDevice(rendition)}
            />
          </Flex>
        </div>
      </button>
    );
  });

  return <Flex flexWrap="wrap">{thumbnails}</Flex>;
};

RenditionSelector.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  renditionIndexToShow: PropTypes.number.isRequired,
  setRenditionIndexToShow: PropTypes.func.isRequired,
};

export default RenditionSelector;
