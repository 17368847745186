import Config from "../config";

// https://dis-dev1.assetful.loblaw.ca/content/dam/loblaw-companies-limited/contentful-asset-picker-test/captions.sbv
// This is being left with 2 inputs to maintain V1 functionality
function buildImageRenditionUrl(meta, extendedMeta) {
  const { contentPath } = meta;
  if (extendedMeta === undefined) {
    return `${Config.akamaiHost}${contentPath}`;
  }
  const { originalAsset, generatedRenditions } = extendedMeta;

  // If source image or rendition metadata is not present
  // return url formed by url including only contentPath
  if (originalAsset === undefined || generatedRenditions === undefined) {
    return `${Config.akamaiHost}${contentPath}`;
  }

  // Forming the default url
  const defaultUrl = originalAsset.url;

  // Selecting maximum rendition, but return defaultUrl if
  // image size is < 50mb and dimensions < 4000px in all directions
  const selectedRendition =
    generatedRenditions.length > 0 ? generatedRenditions[0] : undefined;
  const renditionUrl =
    selectedRendition !== undefined ? selectedRendition.url : defaultUrl;
  const imageSize = originalAsset.size / (1024 * 1024);
  if (
    imageSize < 5 &&
    originalAsset.height < 4000 &&
    originalAsset.width < 4000
  ) {
    return defaultUrl;
  }

  return renditionUrl;
}

export default buildImageRenditionUrl;
