import "regenerator-runtime";
import "dotenv/config"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import

import React from "react";
import ReactDOM from "react-dom";
import { init, locations } from "@contentful/app-sdk";

import "./index.css";
import "@contentful/forma-36-react-components/dist/styles.css";

import Config from "./config";
import AppConfig from "./AppConfig";
import DamEntryEditor from "./DamEntryEditor";
import { UIApp } from "./AssetFieldEditor/indexV1";
import ValidationEditor from "./ValidationFieldEditor";
import AltTextFieldEditor from "./AltTextFieldEditor";

init(async (sdk) => {
  if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
    ReactDOM.render(<AppConfig sdk={sdk} />, document.getElementById("root"));
  } else if (sdk.location.is(locations.LOCATION_ENTRY_FIELD)) {
    // This handles loading DAM 1.0 UI
    await import("./indexV1.css");
    sdk.window.startAutoResizer();

    // TODO: make these values constants
    if (sdk.ids.field === "asset") {
      ReactDOM.render(<UIApp sdk={sdk} />, document.getElementById("root"));
    } else if (sdk.ids.field === "validation") {
      ReactDOM.render(
        <ValidationEditor sdk={sdk} />,
        document.getElementById("root")
      );
    } else if (sdk.ids.field === "isAltTextRequired") {
      ReactDOM.render(
        <AltTextFieldEditor sdk={sdk} />,
        document.getElementById("root")
      );
    }
  } else if (sdk.location.is(locations.LOCATION_ENTRY_EDITOR)) {
    // This handles loading DAM 2.0 UI
    if (sdk.ids.contentType === Config.contentTypeId) {
      ReactDOM.render(
        <DamEntryEditor sdk={sdk} />,
        document.getElementById("root")
      );
    }
  }
});

/**
 * By default, iframe of the extension is fully reloaded on every save of a source file.
 * If you want to use HMR (hot module reload) instead of full reload, uncomment the following lines
 */
// if (module.hot) {
//   module.hot.accept();
// }
