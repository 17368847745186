import * as React from "react";
import PropTypes from "prop-types";
import { find, propEq } from "ramda";
import { Field, FieldWrapper } from "@contentful/default-field-editors";
import { FormControl, Paragraph, TextInput } from "@contentful/f36-components";

import AltTextFieldEditor from "../AltTextFieldEditor";
import AssetFieldEditor from "../AssetFieldEditor/indexV2";
import ValidationFieldEditor from "../ValidationFieldEditor";

const DamEntryEditor = ({ sdk }) => {
  const [title, setTitle] = React.useState(
    () => sdk.entry.fields.title.getValue() || ""
  );

  const onTitleChangeHandler = async ({ target }) => {
    const { value } = target;

    setTitle(value);
    sdk.entry.fields.title.setValue(value);
  };

  // Given the ID of a specific field within the Content Type, will either
  // render the default UI for that field if no elementGenerator is given
  // or will render the field using the elementGenerator, wrapped in Contentful's FieldWrapper
  const generateFieldUI = React.useCallback(
    (fieldId, elementGenerator) => {
      const { name } = find(propEq("id", fieldId), sdk.contentType.fields);

      const localizedSdk = {
        ...sdk,
        field: sdk.entry.fields[fieldId].getForLocale(sdk.locales.default),
      };

      const generateFieldChild = (childSdk) => {
        return <Field sdk={childSdk} />;
      };

      const generateChild = elementGenerator || generateFieldChild;

      return (
        <div className="combined-fields" key={fieldId}>
          <FieldWrapper
            sdk={localizedSdk}
            name={name}
            key={`${fieldId}`}
            className="combined-field"
          >
            {generateChild(localizedSdk)}
          </FieldWrapper>
        </div>
      );
    },
    [sdk]
  );

  return (
    <div className="constrain-width">
      {/*
        For some reason rendering the title input with the generic FieldWrapper/Field
        causes some characters to be dropped when typing in the title
      */}
      <FormControl className="contentful-field" isRequired>
        <FormControl.Label>Title</FormControl.Label>
        <TextInput
          onChange={onTitleChangeHandler}
          value={title}
          maxLength="256"
        />
        <FormControl.HelpText>
          <Paragraph as="span">
            {title.length} characters
            <span style={{ float: "right" }}> Maximum 256 characters</span>
          </Paragraph>
        </FormControl.HelpText>
      </FormControl>

      {generateFieldUI("asset", (localSdk) => (
        <AssetFieldEditor sdk={localSdk} />
      ))}
      {generateFieldUI("isAltTextRequired", (localSdk) => (
        <AltTextFieldEditor sdk={localSdk} />
      ))}
      {generateFieldUI("validation", (localSdk) => (
        <ValidationFieldEditor sdk={localSdk} />
      ))}
    </div>
  );
};

DamEntryEditor.propTypes = {
  sdk: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DamEntryEditor;
