import { createClient } from "contentful-management";

import Config from "../config";

const PAGE_SIZE = 100;

const getCma = (sdk) => {
  return createClient(
    { apiAdapter: sdk.cmaAdapter },
    {
      type: "plain",
      defaults: {
        environmentId: sdk.ids.environment,
        spaceId: sdk.ids.space,
      },
    }
  );
};

const fetchDamEntryPage = async (cmaClient, pageNum) => {
  return cmaClient.entry.getMany({
    query: {
      content_type: Config.contentTypeId,
      skip: pageNum * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
  });
};

const getDamEntries = async (sdk) => {
  const cmaClient = getCma(sdk);

  let damEntries = [];
  let damPage;
  const pageNum = 0;
  do {
    // eslint-disable-next-line no-await-in-loop
    damPage = await fetchDamEntryPage(cmaClient, pageNum);

    damEntries = damEntries.concat(damPage.items);
  } while (damEntries.length < damPage.total);

  return damEntries;
};

const getSignedHeaders = async (sdk, requestToSign) => {
  const cmaClient = getCma(sdk);

  const { additionalHeaders } = await cmaClient.appSignedRequest.create(
    {
      appDefinitionId: sdk.ids.app,
    },
    requestToSign
  );

  return additionalHeaders;
};

const getEntrySnapshots = async (sdk, entryId) => {
  const cmaClient = getCma(sdk);
  const snapshots = await cmaClient.snapshot.getManyForEntry({ entryId });
  return snapshots;
};

export { getDamEntries, getEntrySnapshots, getSignedHeaders };
