import React from "react";
import PropTypes from "prop-types";
import { Asset, HelpText, TextLink } from "@contentful/f36-components";

const DocumentRenderer = ({ assetInfo }) => {
  return (
    <div id="asset-img">
      <Asset src="" title="" type="richtext" />
      <HelpText>
        This content cannot be previewed.{" "}
        <TextLink
          href={assetInfo.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to download
        </TextLink>
      </HelpText>
    </div>
  );
};

DocumentRenderer.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentRenderer;
