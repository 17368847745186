import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Asset,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Radio,
  Text,
  TextInput,
} from "@contentful/f36-components";
import { CheckCircleIcon, DeleteIcon } from "@contentful/f36-icons";

import AssetRenderer from "./AssetRenderer";
import UnpublishedAssetDisplay from "./UnpublishedAssetDisplay";
import Caption from "./Caption";
import ExtendedMeta from "./ExtendedMeta";
import RenditionSelector from "./RenditionSelector";
import DeviceIcons from "./DeviceIcons";
import {
  LANGUAGES,
  DEVICES,
  ACTION,
  getCropForDevice,
  doesAssetContainDevice,
} from "./utils";

const mimeTypesToHideRenditions = ["image/gif"];

const includesAllDevice = (devices) => {
  return devices && devices.length === 3;
};

const AssetAccordian = ({
  isLoading,
  isV2Enabled,
  asset,
  updateAsset,
  deleteAsset,
  openAssetPicker,
  updateCaptionFieldOnClear,
  refreshFn,
}) => {
  const [renditionIndexToShow, setRenditionIndexToShow] = React.useState(0);
  const [renditionToShow, setRenditionToShow] = React.useState({});
  const [useSpecificDevices, setUseSpecificDevices] = React.useState(true);

  const [assetName] = React.useState(() => decodeURI(asset.name));
  const [assetPath] = React.useState(() => {
    // Remove the file name, remove the initial "content" part of the path
    // The remaining path is the folder structure of the asset in the DAM
    return asset.contentPath
      .replace(`/${assetName}`, "")
      .replace("/content/", "")
      .replace(/\//g, " > ");
  });

  // Stringify in a variable to satisfy eslint
  const strigifiedRenditions = JSON.stringify(asset.renditions);
  React.useEffect(() => {
    const rendition = asset.renditions[renditionIndexToShow];
    // Include values needed for children components from the base asset
    setRenditionToShow({
      ...rendition,
      type: asset.type,
      name: asset.name,
      scene7FileAVS: asset.scene7FileAVS,
    });

    setUseSpecificDevices(!includesAllDevice(rendition.devices));
  }, [
    asset.name,
    asset.renditions,
    strigifiedRenditions,
    asset.scene7FileAVS,
    asset.type,
    renditionIndexToShow,
  ]);

  const getAssetResolution = () => {
    const rendition = asset.renditions[renditionIndexToShow];

    if (!rendition.width || !rendition.height) {
      return "";
    }

    let orientation;
    if (rendition.width === rendition.height) {
      orientation = "Square";
    } else if (rendition.width > rendition.height) {
      orientation = "Landscape";
    } else {
      orientation = "Portrait";
    }

    return `${orientation} ${rendition.width} x ${rendition.height}`;
  };

  const update = async (language, device) => {
    if (doesAssetContainDevice(renditionToShow, device)) {
      await updateAsset(asset.displayIndex, renditionIndexToShow, language, {
        action: ACTION.remove,
        device,
      });
    } else {
      await updateAsset(asset.displayIndex, renditionIndexToShow, language, {
        action: ACTION.add,
        device,
      });
    }
  };

  return (
    <Accordion align="start" className="accordion" key={asset.displayIndex}>
      <Accordion.Item
        className="accordion-item"
        title={
          <Flex justifyContent="space-between" fullWidth>
            <Text fontWeight="fontWeightDemiBold">Name:</Text>
            <Text>{assetName}</Text>
            <IconButton
              variant="transparent"
              className="delete-button"
              icon={<DeleteIcon />}
              onClick={() => deleteAsset(asset.displayIndex)}
            />
          </Flex>
        }
      >
        <Grid
          className="asset-header"
          columns="100px 1fr"
          rowGap="spacingM"
          columnGap="spacingM"
        >
          <Grid.Item>Path:</Grid.Item>
          <Grid.Item>{assetPath}</Grid.Item>
          <Grid.Item>Language:</Grid.Item>
          <Grid.Item>
            <Radio.Group
              name={`language-options-${asset.displayIndex}`}
              value={renditionToShow.language || ""}
              className="language-group"
              onChange={(e) => {
                update(e.target.value, renditionToShow.devices);
              }}
            >
              <Radio value={LANGUAGES.bilingual} id={LANGUAGES.bilingual}>
                Bilingual
              </Radio>
              <Radio value={LANGUAGES.english} id={LANGUAGES.english}>
                English
              </Radio>
              <Radio value={LANGUAGES.french} id={LANGUAGES.french}>
                French
              </Radio>
            </Radio.Group>
          </Grid.Item>
          <Grid.Item>Device:</Grid.Item>
          <Grid.Item>
            <Grid
              columns="1fr 1fr 1fr"
              rowGap="spacingXs"
              columnGap="spacingXl"
            >
              <Grid.Item>Desktop</Grid.Item>
              <Grid.Item>Tablet</Grid.Item>
              <Grid.Item>Mobile</Grid.Item>
              <Grid.Item>
                <TextInput
                  value={getCropForDevice(
                    asset,
                    renditionToShow.language,
                    DEVICES.desktop
                  )}
                  type="text"
                  isDisabled
                />
              </Grid.Item>
              <Grid.Item>
                <TextInput
                  value={getCropForDevice(
                    asset,
                    renditionToShow.language,
                    DEVICES.tablet
                  )}
                  type="text"
                  isDisabled
                />
              </Grid.Item>
              <Grid.Item>
                <TextInput
                  value={getCropForDevice(
                    asset,
                    renditionToShow.language,
                    DEVICES.mobile
                  )}
                  type="text"
                  isDisabled
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
        <Flex className="asset-wrapper">
          {asset.isPublished && (
            <>
              <div className="asset-container">
                <AssetRenderer assetInfo={renditionToShow} />
                <Flex gap="spacingS" className="device-icons-container">
                  <DeviceIcons
                    containerClass="asset-device-icon"
                    iconClass="react-icons"
                    doesAssetContainDevice={doesAssetContainDevice(
                      renditionToShow
                    )}
                  />
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="fontWeightDemiBold">
                    {renditionToShow.generatedCropName}
                  </Text>
                  <Text>{getAssetResolution()}</Text>
                </Flex>
              </div>
              <div className="device-selector">
                <Text fontWeight="fontWeightDemiBold">Applicable Devices</Text>
                <Radio.Group
                  name={`device-options-${asset.displayIndex}`}
                  value={useSpecificDevices ? "selectedDevices" : "allDevices"}
                  className="deviceRadioGroup"
                >
                  <Radio
                    value="allDevices"
                    id="allDevices"
                    onChange={() => {
                      setUseSpecificDevices(false);
                      update(renditionToShow.language, DEVICES.all);
                    }}
                  >
                    All Devices
                  </Radio>
                  <Radio
                    value="selectedDevices"
                    id="selectedDevices"
                    onChange={() => setUseSpecificDevices(true)}
                  >
                    Selected Devices
                  </Radio>
                </Radio.Group>
                <Checkbox.Group
                  name={`specific-device-options${asset.displayIndex}`}
                  value={renditionToShow.devices || []}
                  className={`deviceCheckboxGroup ${
                    useSpecificDevices ? "" : "hidden"
                  }`}
                >
                  <Checkbox
                    value={DEVICES.desktop}
                    id={DEVICES.desktop}
                    isChecked={doesAssetContainDevice(
                      renditionToShow,
                      DEVICES.desktop
                    )}
                    onChange={() =>
                      update(renditionToShow.language, DEVICES.desktop)
                    }
                  >
                    Desktop
                  </Checkbox>
                  <Checkbox
                    value={DEVICES.tablet}
                    id={DEVICES.tablet}
                    isChecked={doesAssetContainDevice(
                      renditionToShow,
                      DEVICES.tablet
                    )}
                    onChange={() =>
                      update(renditionToShow.language, DEVICES.tablet)
                    }
                  >
                    Tablet
                  </Checkbox>
                  <Checkbox
                    value={DEVICES.mobile}
                    id={DEVICES.mobile}
                    isChecked={doesAssetContainDevice(
                      renditionToShow,
                      DEVICES.mobile
                    )}
                    onChange={() =>
                      update(renditionToShow.language, DEVICES.mobile)
                    }
                  >
                    Mobile
                  </Checkbox>
                </Checkbox.Group>
              </div>
            </>
          )}
          {!asset.isPublished && (
            <div className="asset-preview">
              <Asset
                status="archived"
                src=""
                title=""
                className="unpublished-asset-icon"
              />
            </div>
          )}
        </Flex>
        {!isLoading && !asset.isPublished && (
          <UnpublishedAssetDisplay
            assetMeta={asset}
            isV2Enabled={isV2Enabled}
            refreshFn={refreshFn}
          />
        )}
        {!isLoading &&
          asset.isPublished &&
          asset.renditions.length > 1 &&
          !mimeTypesToHideRenditions.includes(asset.mimeType) && (
            <RenditionSelector
              assetInfo={asset}
              extendedMeta={asset.extendedMeta}
              renditionIndexToShow={renditionIndexToShow}
              setRenditionIndexToShow={setRenditionIndexToShow}
            />
          )}
        {!isLoading && asset.isPublished && asset.extendedMeta && (
          <>
            <div className="published-message-container">
              <div className="validation-message">
                <CheckCircleIcon variant="positive" />
                <Text className="published-message">
                  Asset published in DAM
                </Text>
              </div>
            </div>
            <ExtendedMeta extendedMeta={asset.extendedMeta} />
          </>
        )}
        {!isLoading && (
          <Caption
            isLoading={isLoading}
            assetMeta={asset}
            onButtonClick={() => openAssetPicker(asset.displayIndex)}
            updateFieldOnClear={() =>
              updateCaptionFieldOnClear(asset.displayIndex)
            }
          />
        )}
      </Accordion.Item>
    </Accordion>
  );
};

AssetAccordian.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isV2Enabled: PropTypes.bool.isRequired,
  asset: PropTypes.objectOf(PropTypes.any).isRequired,
  updateAsset: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  openAssetPicker: PropTypes.func.isRequired,
  updateCaptionFieldOnClear: PropTypes.func.isRequired,
  refreshFn: PropTypes.func.isRequired,
};

export default AssetAccordian;
