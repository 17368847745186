import React from 'react';

export function CalendarIcon(props: {
  className?: string;
  onClick?: React.MouseEventHandler<SVGElement>;
}) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="-1 -1 18 19"
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}>
      <g fill="none" fillRule="evenodd">
        <g fill="#536171">
          <path d="M2.071 14.929v-2.411h2.411v2.41h-2.41zm2.947 0v-2.411h2.678v2.41H5.018zM2.07 11.982V9.304h2.411v2.678h-2.41zm2.947 0V9.304h2.678v2.678H5.018zM2.07 8.768v-2.41h2.411v2.41h-2.41zm6.161 6.16v-2.41h2.679v2.41H8.232zm-3.214-6.16v-2.41h2.678v2.41H5.018zm6.428 6.16v-2.41h2.411v2.41h-2.41zm-3.214-2.946V9.304h2.679v2.678H8.232zM5.286 4.75a.275.275 0 0 1-.268.268h-.536a.275.275 0 0 1-.268-.268V2.34c0-.143.126-.269.268-.269h.536c.142 0 .268.126.268.268V4.75zm6.16 7.232V9.304h2.411v2.678h-2.41zM8.232 8.768v-2.41h2.679v2.41H8.232zm3.214 0v-2.41h2.411v2.41h-2.41zm.268-4.018a.275.275 0 0 1-.268.268h-.535a.275.275 0 0 1-.268-.268V2.34c0-.143.125-.269.268-.269h.535c.143 0 .268.126.268.268V4.75zm3.215-.536c0-.586-.486-1.071-1.072-1.071h-1.071v-.804c0-.736-.603-1.339-1.34-1.339h-.535c-.737 0-1.34.603-1.34 1.34v.803H6.357v-.804C6.357 1.603 5.754 1 5.017 1h-.535c-.736 0-1.34.603-1.34 1.34v.803h-1.07C1.484 3.143 1 3.628 1 4.214V14.93C1 15.515 1.485 16 2.071 16h11.786c.586 0 1.072-.485 1.072-1.071V4.214z" />
        </g>
      </g>
    </svg>
  );
}
