import React from "react";
import PropTypes from "prop-types";

import ImageRenderer from "./ImageRenderer";
import VideoRenderer from "./VideoRenderer";
import DocumentRenderer from "./DocumentRenderer";

const getComponent = {
  image: ImageRenderer,
  video: VideoRenderer,
  other: DocumentRenderer,
};

const AssetRenderer = ({ assetInfo }) => {
  const source = assetInfo.url;
  const PreviewComponent = getComponent[assetInfo.type];
  return <>{source && <PreviewComponent assetInfo={assetInfo} />}</>;
};

AssetRenderer.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AssetRenderer;
