/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import { Note, Button, Spinner } from "@contentful/forma-36-react-components";

import Config from "../config";
import { fetchAssetMetadata } from "../util/fetch-metadata";

import AssetRenderer from "./AssetRenderer";
import AssetDetails from "./AssetDetails";
import UnpublishedAssetDisplay from "./UnpublishedAssetDisplay";
import ExtendedMeta from "./ExtendedMeta";
import buildImageRenditionUrl from "./ImageRenditionUrl";
import Caption from "./Caption";
import ASSET_TYPE from "./common";

function typeForMime(mimeType) {
  if (mimeType.includes("image")) {
    return ASSET_TYPE.IMAGE; // "image"
  }
  if (mimeType.includes("video")) {
    return ASSET_TYPE.VIDEO; // "video";
  }
  if (mimeType.includes("text/vtt")) {
    return ASSET_TYPE.CAPTION;
  }

  return ASSET_TYPE.OTHER; // "other";
}

function buildMeta(parsedData) {
  const aemUrl = new URL(parsedData.data[0].url);
  const type = typeForMime(parsedData.data[0].type);

  return {
    mimeType: parsedData.data[0].type,
    name: parsedData.data[0].title,
    contentPath: aemUrl.pathname,
    type,
  };
}

async function checkIfDocumentPublished(assetUrl) {
  let result;
  try {
    result = await fetch(assetUrl);
  } catch (e) {
    console.error(`ERROR checking published status for ${assetUrl}:`, e);
    return false;
  }

  return result.ok;
}

async function checkIfAssestPublished(assetUrl, type, mimeType) {
  const now = new Date();
  const cacheBustingUrl = assetUrl.includes("?")
    ? `${assetUrl}&t=${now.valueOf()}`
    : `${assetUrl}?t=${now.valueOf()}`;
  return checkIfDocumentPublished(cacheBustingUrl, mimeType);
}

function buildPublicUrl(meta, extendedMeta) {
  const { type, contentPath } = meta;
  // https://s7d1.scene7.com/is/image/loblawstage/unpublished:Large
  if (type === ASSET_TYPE.VIDEO || type === ASSET_TYPE.CAPTION) {
    const videoFilename = contentPath.replace("/content/dam", "");
    return `${Config.dynamicMediaHost}/is/content/${Config.dynamicMediaTenant}${videoFilename}`;
  }
  if (type === ASSET_TYPE.IMAGE) {
    return buildImageRenditionUrl(meta, extendedMeta);
  }
  return `${Config.akamaiHost}${contentPath}`;
}

// TODO: move to arrow functions for class functions
// eslint-disable-next-line import/prefer-default-export
export class UIApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      asset: props.sdk.field.getValue(props.sdk.field.locale),
      assetMeta: props.sdk.entry.fields.assetMeta.getValue(
        props.sdk.field.locale
      ),
      popup: null,
      isLoading: true,
      extendedMeta: undefined, // metadata pulled from API
      locale: props.sdk.field.locale,
    };

    // Bind the this context to the caption callback function
    this.onButtonClick = this.onButtonClick.bind(this);
    this.updateCaptionFieldOnClear = this.updateCaptionFieldOnClear.bind(this);
  }

  async componentDidMount() {
    const { sdk } = this.props;
    const { locale } = this.state;

    this.detachValueChangeHandler = sdk.field.onValueChanged((e) =>
      this.onExternalValueChange(e)
    );
    this.detachMetaValueChangeHandler = sdk.entry.fields.assetMeta.onValueChanged(
      locale,
      (e) => this.onExternalMetaValueChange(e)
    );

    window.addEventListener("message", (e) => this.handleAssetSelectorEvent(e));

    this.updateFieldOnLoad();
  }

  componentWillUnmount() {
    if (this.detachValueChangeHandler) {
      this.detachValueChangeHandler();
    }
    if (this.detachMetaValueChangeHandler) {
      this.detachMetaValueChangeHandler();
    }
  }

  handleAssetSelectorEvent(event) {
    if (typeof event.data === "string") {
      const parsedData = JSON.parse(event.data);

      if (parsedData && Object.entries(parsedData).length !== 0) {
        if (parsedData.config && parsedData.config.action !== "close") {
          const newMetaValue = buildMeta(parsedData);
          this.updateFieldOnNewSelection(newMetaValue);
          this.state.popup.close();
        }
      }
    }
  }

  onExternalMetaValueChange(assetMeta) {
    this.setState({ assetMeta });
    // this.setExtendedMeta(assetMeta);
  }

  onExternalValueChange(asset) {
    this.setState({ asset });
  }

  onButtonClick() {
    const windowOpen = window.open(
      `${Config.aemDispatcherHost}${Config.aemAssetPickerPath}`,
      "Adobe DAM Asset Picker"
    );
    this.setState({
      popup: windowOpen,
    });
  }

  async setExtendedMeta(assetMeta) {
    this.setState({ extendedMeta: undefined });

    if (assetMeta && assetMeta.contentPath) {
      const extendedMeta = await fetchAssetMetadata(
        this.props.sdk,
        Config,
        assetMeta.contentPath
      );
      this.setState({ extendedMeta });

      // must return extendedMeta because we are calling this from validateAndSetValue
      // probably not how that should have been written!
      return extendedMeta;
    }

    return undefined;
  }

  checkLatestPublishedAsset() {
    if (this.state.asset === undefined) return;
    this.props.sdk.space
      .getEntrySnapshots(this.props.sdk.ids.entry)
      .then((publishedVersions) => {
        if (publishedVersions.items.length === 0) {
          return;
        }
        const latestSnapshot = publishedVersions.items.reduce(
          (topItem, currentItem) => {
            if (
              topItem &&
              topItem.snapshot.sys.publishedVersion >
                currentItem.snapshot.sys.publishedVersion
            ) {
              return topItem;
            }
            return currentItem;
          },
          undefined
        );
        const latestPublishedAssetUrl =
          latestSnapshot.snapshot.fields.asset[this.state.locale].url;
        const latestPublishedAssetName =
          latestSnapshot.snapshot.fields.assetMeta[this.state.locale].name;
        if (
          latestPublishedAssetUrl !== this.state.asset.url &&
          latestPublishedAssetName === this.state.assetMeta.name
        ) {
          this.setState({ republishMessage: true });
        }
      });
  }

  async updateFieldOnClear() {
    this.setState({ isLoading: true, extendedMeta: undefined });

    await this.props.sdk.entry.fields.asset.removeValue(this.state.locale);
    await this.props.sdk.entry.fields.assetMeta.removeValue(this.state.locale);

    this.setState({ isLoading: false });
  }

  async updateCaptionFieldOnClear() {
    this.setState({ isLoading: true });

    // const newAssetMeta = { ...this.state.assetMeta, caption: undefined }; //reset caption meta data
    const newAssetMeta = { ...this.state.assetMeta, caption: null }; // reset caption meta data
    // const newAsset = { ...this.state.asset, captionUrl: undefined }; // reset caption url
    const newAsset = { ...this.state.asset, captionUrl: null }; // reset caption url using null object instead of undefined

    await this.props.sdk.entry.fields.asset.setValue(
      newAsset,
      this.state.locale
    );
    await this.props.sdk.entry.fields.assetMeta.setValue(
      newAssetMeta,
      this.state.locale
    );

    this.setState({ isLoading: false });
  }

  // caption vs standard asset meta information
  async updateFieldOnNewSelection(newMetaValue) {
    this.setState({ isLoading: true });

    if (newMetaValue.type === ASSET_TYPE.CAPTION) {
      await this.updateFieldOnNewSelectionCaption(newMetaValue);
    } else {
      await this.updateFieldOnNewSelectionAsset(newMetaValue);
    }

    this.setState({ isLoading: false });
  }

  async updateFieldOnNewSelectionAsset(newMetaValue) {
    await this.props.sdk.entry.fields.asset.removeValue(this.state.locale);

    let mergedMetaValue = newMetaValue;
    if (
      newMetaValue.type === ASSET_TYPE.VIDEO &&
      this.state.assetMeta &&
      this.state.assetMeta.caption
    ) {
      mergedMetaValue = {
        ...newMetaValue,
        caption: this.state.assetMeta.caption,
      };
    }

    await this.props.sdk.entry.fields.assetMeta.setValue(
      mergedMetaValue,
      this.state.locale
    );

    //  mergedMetaValue
    await this.validateAndSetValue(mergedMetaValue);
  }

  // where to store caption in assetMeta information
  async updateFieldOnNewSelectionCaption(captionMetaValue) {
    // await this.props.sdk.entry.fields.asset.removeValue(this.state.locale);

    // extendedMeta can be sent undefined for caption flow
    const publicUrl = buildPublicUrl(captionMetaValue, undefined);

    const isPublished = await checkIfAssestPublished(
      publicUrl,
      captionMetaValue.type,
      captionMetaValue.mimeType
    );

    let captionUrl = null;
    if (isPublished) captionUrl = publicUrl;

    const newValue = { ...captionMetaValue, captionUrl };

    const mergedMetaValue = {
      ...this.state.assetMeta,
      caption: newValue, // it should overwrite the previous caption if exists
    };

    await this.props.sdk.entry.fields.assetMeta.setValue(
      mergedMetaValue,
      this.state.locale
    );

    await this.validateAndSetCaptionValue(mergedMetaValue);
  }

  async updateFieldOnLoad() {
    this.setState({ isLoading: true });

    if (this.state.assetMeta) {
      await this.validateAndSetValue(this.state.assetMeta);
    }

    this.setState({ isLoading: false });
    // check if the current asset url has changed
    // compared to the latest published asset
    this.checkLatestPublishedAsset();
  }

  // set caption url in asset object
  async validateAndSetCaptionValue(assetMeta) {
    const meta = assetMeta.caption;

    if (!meta || !this.state.asset) return;

    const newValue = { ...this.state.asset, captionUrl: meta.captionUrl };

    await this.props.sdk.field.setValue(newValue, this.state.locale);
  }

  async validateAndSetValue(meta) {
    // not grabbing meta from state in case the lifecycle isn't updated yet
    const extendedMeta = await this.setExtendedMeta(meta);
    const publicUrl = buildPublicUrl(meta, extendedMeta);
    const resizedUrl =
      meta.type === ASSET_TYPE.IMAGE ? `${publicUrl}?imwidth=600` : publicUrl;

    // Verify if asset is published by using a resized version rather than
    // the original to decrease response time on determining asset publication.
    const isPublished = await checkIfAssestPublished(
      resizedUrl,
      meta.type,
      meta.mimeType
    );

    if (!isPublished) {
      await this.props.sdk.field.removeValue(this.state.locale);
    } else {
      let captionUrl = null;
      if (
        this.state.assetMeta &&
        this.state.assetMeta.caption &&
        this.state.assetMeta.caption.captionUrl
      ) {
        captionUrl = this.state.assetMeta.caption.captionUrl;
      }

      let newValue;
      if (captionUrl) {
        newValue = {
          url: publicUrl,
          mimeType: meta.mimeType,
          type: meta.type,
          ...(extendedMeta &&
            extendedMeta.scene7FileAVS && {
              scene7FileAVS: extendedMeta.scene7FileAVS,
            }),
          captionUrl, // copy caption url if caption is published
        };
      } else {
        newValue = {
          url: publicUrl,
          mimeType: meta.mimeType,
          type: meta.type,
          ...(extendedMeta &&
            extendedMeta.scene7FileAVS && {
              scene7FileAVS: extendedMeta.scene7FileAVS,
            }),
        };
      }

      // why we need to update value if value is not changed ????

      await this.props.sdk.field.setValue(newValue, this.state.locale);
    }

    return isPublished;
  }

  render() {
    const { asset, isLoading, assetMeta, extendedMeta } = this.state;
    return (
      <div className="asset-selector">
        {asset && assetMeta && (
          <div className="asset-display">
            <div className="asset-details">
              <AssetDetails assetInfo={asset} assetMeta={assetMeta} />
            </div>
            <div className="asset-preview">
              <AssetRenderer assetInfo={asset} />
            </div>
          </div>
        )}
        {!isLoading && !asset && assetMeta && (
          <UnpublishedAssetDisplay assetMeta={assetMeta} />
        )}
        {!isLoading && extendedMeta && (
          <ExtendedMeta extendedMeta={extendedMeta} />
        )}
        <Button
          buttonType="positive"
          isFullWidth={false}
          onClick={() => this.onButtonClick()}
          disabled={isLoading}
          className="action-button"
          data-test-name="select-asset-button"
        >
          Select asset from DAM
        </Button>
        <Button
          buttonType="negative"
          isFullWidth={false}
          onClick={() => this.updateFieldOnClear()}
          disabled={!assetMeta || isLoading}
          className="action-button"
        >
          Remove Asset
        </Button>
        <br /> <br />
        {this.state.republishMessage && (
          <Note noteType="warning" className="note">
            This assets&apos; quality has been updated in Assetful. Please
            &quot;Publish changes&quot; for this entry to ensure the best asset
            is shown to users.
          </Note>
        )}
        {!isLoading && (
          <Caption
            isLoading={isLoading}
            assetMeta={assetMeta}
            onButtonClick={this.onButtonClick}
            updateFieldOnClear={this.updateCaptionFieldOnClear}
          />
        )}
        {isLoading && <Spinner />}
      </div>
    );
  }
}

UIApp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sdk: PropTypes.object.isRequired,
};
