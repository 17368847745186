import React from "react";
import PropTypes from "prop-types";

const ImageRenderer = ({ assetInfo }) => {
  const resizedUrl = `${assetInfo.url}?imwidth=450`;
  return <img src={resizedUrl} alt={assetInfo.name} className="asset-img" />;
};

ImageRenderer.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ImageRenderer;
