import React from "react";
import PropTypes from "prop-types";
import {
  CopyButton,
  Form,
  FormControl,
  TextInput,
} from "@contentful/f36-components";

const AssetDetails = ({ assetInfo, assetMeta }) => {
  return (
    <Form>
      <FormControl id="name">
        <FormControl.Label>Name</FormControl.Label>
        <TextInput name="name" value={assetMeta.name} isDisabled />
      </FormControl>

      <FormControl id="type">
        <FormControl.Label>MIME Type</FormControl.Label>
        <TextInput name="type" value={assetInfo.mimeType} isDisabled />
      </FormControl>

      <FormControl id="url">
        <FormControl.Label>URL</FormControl.Label>
        <TextInput.Group>
          <TextInput name="url" value={assetInfo.url} isDisabled />
          <CopyButton value={assetInfo.url} />
        </TextInput.Group>
      </FormControl>
    </Form>
  );
};

AssetDetails.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  assetMeta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AssetDetails;
