import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Config from "../config";

const VideoRenderer = ({ assetInfo }) => {
  const { scene7FileAVS: asset, url, name } = assetInfo;

  const [s7ScriptId] = React.useState(`${Config.contentTypeId}-scene7-script`);

  const initVideo = React.useCallback(() => {
    // if there is an AVS asset, use it. Otherwise, fallback to video URL
    const reference = asset ? { asset } : { video: url };

    // eslint-disable-next-line no-undef
    const s7videoviewer = new s7viewers.VideoViewer({
      containerId: `s7video_div${name}`,
      params: {
        serverurl: `${Config.dynamicMediaHost}/is/image/`,
        videoserverurl: `${Config.dynamicMediaHost}/is/content/`,
        ...reference,
      },
    });
    s7videoviewer.init();
  }, [asset, name, url]);

  useEffect(() => {
    if (document.getElementById(s7ScriptId)) {
      // scene7 script already loaded;
      initVideo();
    } else {
      const head = document.getElementsByTagName("head").item(0);
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.onload = initVideo;
      script.setAttribute(
        "src",
        `${Config.dynamicMediaHost}/s7viewers/html5/js/VideoViewer.js`
      );
      head.appendChild(script);
    }
  }, [asset, url, initVideo, s7ScriptId]);

  return (
    <>
      <div id={`s7video_div${name}`} />
    </>
  );
};

VideoRenderer.propTypes = {
  assetInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VideoRenderer;
