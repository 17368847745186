import {
  __,
  curry,
  equals,
  findIndex,
  includes,
  intersection,
  is,
  pluck,
  prop,
  propEq,
  reject,
  sortBy,
} from "ramda";

import Config from "../config";

const LANGUAGES = {
  bilingual: "bilingual",
  english: "english",
  french: "french",
};

const AEM_LANGUAGES = {
  bilingual: "Bi-Lingual",
  english: "English",
  french: "French",
};

const DEVICES = {
  all: "all",
  desktop: "desktop",
  mobile: "mobile",
  tablet: "tablet",
};

const ACTION = {
  add: "add",
  remove: "remove",
};

const getAssetLanguage = (asset) => {
  let assetDeviceLanguage;
  let assetNoDeviceLanguage;

  for (let i = 0; i < asset.renditions.length; i += 1) {
    const rendition = asset.renditions[i];

    if (!rendition.devices || rendition.devices.length === 0) {
      if (
        (rendition.language === LANGUAGES.english &&
          assetNoDeviceLanguage === LANGUAGES.french) ||
        (rendition.language === LANGUAGES.french &&
          assetNoDeviceLanguage === LANGUAGES.english)
      ) {
        assetNoDeviceLanguage = LANGUAGES.bilingual;
      } else {
        assetNoDeviceLanguage = rendition.language;
      }
    } else if (
      (rendition.language === LANGUAGES.english &&
        assetDeviceLanguage === LANGUAGES.french) ||
      (rendition.language === LANGUAGES.french &&
        assetDeviceLanguage === LANGUAGES.english)
    ) {
      assetDeviceLanguage = LANGUAGES.bilingual;
    } else {
      assetDeviceLanguage = rendition.language;
    }
  }

  return assetDeviceLanguage || assetNoDeviceLanguage;
};

const getCropForDevice = (asset, language, device) => {
  for (let i = 0; i < asset.renditions.length; i += 1) {
    const rendition = asset.renditions[i];

    if (
      (rendition.language === language ||
        rendition.language === LANGUAGES.bilingual) &&
      rendition.devices.includes(device)
    ) {
      return rendition.generatedCropName;
    }
  }

  return "-";
};

const buildAssetPickerUrl = (allowMultiple, aemPath) => {
  let url = `${Config.aemDispatcherHost}${Config.aemAssetPickerPath}${Config.aemAssetPickerBasePath}`;

  if (aemPath && aemPath !== "") {
    url += `/${aemPath}`;
  }

  if (allowMultiple) {
    return `${url}?mode=multiple`;
  }

  return url;
};

const getUnconfiguredDevices = (enAssets, frAssets) => {
  // Leave bilingual devices empty, as they are inserted at the end whereas En/Fr are removed as the assets are processed
  const remainingDevices = {
    [LANGUAGES.bilingual]: [],
    [LANGUAGES.english]: [DEVICES.desktop, DEVICES.mobile, DEVICES.tablet],
    [LANGUAGES.french]: [DEVICES.desktop, DEVICES.mobile, DEVICES.tablet],
  };

  const updateRemainingDevices = (assetList) => {
    for (let i = 0; i < assetList.length; i += 1) {
      for (let n = 0; n < assetList[i].renditions.length; n += 1) {
        const rendition = assetList[i].renditions[n];

        remainingDevices[rendition.language] = reject(
          includes(__, rendition.devices),
          remainingDevices[rendition.language]
        );

        if (
          rendition.language === LANGUAGES.bilingual ||
          rendition.language === LANGUAGES.english
        ) {
          remainingDevices[LANGUAGES.english] = reject(
            includes(__, rendition.devices),
            remainingDevices[LANGUAGES.english]
          );
        }

        if (
          rendition.language === LANGUAGES.bilingual ||
          rendition.language === LANGUAGES.french
        ) {
          remainingDevices[LANGUAGES.french] = reject(
            includes(__, rendition.devices),
            remainingDevices[LANGUAGES.french]
          );
        }
      }
    }
  };
  updateRemainingDevices(enAssets);
  updateRemainingDevices(frAssets);

  // Remaining bilingual devices are the devices that remain for both english and french
  remainingDevices[LANGUAGES.bilingual] = intersection(
    remainingDevices[LANGUAGES.english],
    remainingDevices[LANGUAGES.french]
  );

  return remainingDevices;
};

const getDefaultDevices = (enAssets, frAssets, language) => {
  const unconfiguredDevices = getUnconfiguredDevices(enAssets, frAssets);

  if (
    unconfiguredDevices[LANGUAGES.bilingual].length > 0 &&
    (!language || language === LANGUAGES.bilingual)
  ) {
    return {
      language: LANGUAGES.bilingual,
      devices: unconfiguredDevices[LANGUAGES.bilingual],
    };
  }
  if (
    unconfiguredDevices[LANGUAGES.english].length > 0 &&
    (!language || language === LANGUAGES.english)
  ) {
    return {
      language: LANGUAGES.english,
      devices: unconfiguredDevices[LANGUAGES.english],
    };
  }
  if (
    unconfiguredDevices[LANGUAGES.french].length > 0 &&
    (!language || language === LANGUAGES.french)
  ) {
    return {
      language: LANGUAGES.french,
      devices: unconfiguredDevices[LANGUAGES.french],
    };
  }

  return {
    language: language || LANGUAGES.bilingual,
    devices: [],
  };
};

/**
 * Determines the new language an asset/crop should use when removing devices from it due to a change
 * in another crop.
 * The scenario where this may happen is if the rendition being checked is bilingual with a specific device,
 * and the rendition being updated is being set to a specific language for the same device.
 *
 * @param {object} rendition - The rendition to determine the new language for
 * @param {string} newLanguage - The updated/current language of the crop being changed
 * @param {string|array} changedDevice - The updated/current devices of the crop being changed
 */
const getNewLanguage = (rendition, newLanguage, changedDevice) => {
  // Check for new language = bilingual first so we can assume it's not bilingual in the follow checks
  if (newLanguage === LANGUAGES.bilingual) {
    return rendition.language;
  }

  // The only scenario where we may need to update a renditions language is if its biligual and it needs
  // to be "demoted" to only english or only french
  if (rendition.language === LANGUAGES.bilingual) {
    // We only "demote" a rendition if it is having ALL of it's devices of a sepcific language removed or
    // if it has exactly 1 device and that device is being removed
    // If a rendition has multiple devices and only 1 is being removed, instead of demoting the language
    // we fully remove that 1 device
    if (
      changedDevice === DEVICES.all ||
      (rendition.devices.length === 1 &&
        rendition.devices.includes(changedDevice))
    ) {
      return newLanguage === LANGUAGES.english
        ? LANGUAGES.french
        : LANGUAGES.english;
    }
  }

  return rendition.language;
};

/**
 *
 *
 * @param {object|array} assetList -
 * @param {number} renditionIndexToIgnore -
 * @param {string} language -
 * @param {string} device -
 */
const ensureUniqueDevice = (
  assetList,
  assetIndexBeingUpdated,
  renditionIndexToIgnore,
  language,
  device
) => {
  return assetList.map((asset, assetIndex) => {
    return {
      ...asset,
      renditions: asset.renditions.map((rendition, renditionIndex) => {
        if (
          rendition.devices.length === 0 ||
          (assetIndex === assetIndexBeingUpdated &&
            renditionIndexToIgnore === renditionIndex)
        ) {
          return rendition;
        }

        // If neither the rendition's language nor the updated rendition's language is bilingual,
        // and the rendition and updated renditions' languages aren't the same, then we don't need to update
        // ex: if the updated rendition is english and has had desktop added to it's devices, we only need to update
        // this rendition if it is either english or bilingual
        if (
          language !== LANGUAGES.bilingual &&
          rendition.language !== LANGUAGES.bilingual &&
          rendition.language !== language
        ) {
          return rendition;
        }

        const newLanguage = getNewLanguage(rendition, language, device);

        let devices;
        if (rendition.language === newLanguage) {
          if (device === DEVICES.all) {
            devices = [];
          } else {
            devices = reject(equals(device), rendition.devices);
          }
        } else {
          devices = rendition.devices;
        }

        return {
          ...rendition,
          language: newLanguage,
          devices,
        };
      }),
    };
  });
};

const updateAssetArrays = (
  enAssets,
  frAssets,
  indexToUpdate,
  renditionToUpdate,
  language,
  device
) => {
  let newEnAssets = enAssets;
  let newFrAssets = frAssets;
  if (is(Array, device)) {
    for (let i = 0; i < device.length; i += 1) {
      newEnAssets = ensureUniqueDevice(
        newEnAssets,
        indexToUpdate,
        renditionToUpdate,
        language,
        device[i]
      );
      newFrAssets = ensureUniqueDevice(
        newFrAssets,
        indexToUpdate,
        renditionToUpdate,
        language,
        device[i]
      );
    }
  } else if (device) {
    newEnAssets = ensureUniqueDevice(
      enAssets,
      indexToUpdate,
      renditionToUpdate,
      language,
      device
    );
    newFrAssets = ensureUniqueDevice(
      frAssets,
      indexToUpdate,
      renditionToUpdate,
      language,
      device
    );
  }

  const enAssetIndex = findIndex(
    propEq("displayIndex", indexToUpdate),
    newEnAssets
  );
  const frAssetIndex = findIndex(
    propEq("displayIndex", indexToUpdate),
    newFrAssets
  );

  const updatedAsset =
    enAssetIndex !== -1 ? newEnAssets[enAssetIndex] : newFrAssets[frAssetIndex];
  updatedAsset.renditions[renditionToUpdate].language = language;

  if (device === DEVICES.all) {
    updatedAsset.renditions[renditionToUpdate].devices = [
      DEVICES.desktop,
      DEVICES.tablet,
      DEVICES.mobile,
    ];
  } else if (device !== undefined && !is(Array, device)) {
    updatedAsset.renditions[renditionToUpdate].devices = [
      ...updatedAsset.renditions[renditionToUpdate].devices,
      device,
    ];
  }

  if (enAssetIndex !== -1 && language === LANGUAGES.french) {
    newEnAssets.splice(enAssetIndex, 1);
  } else if (
    enAssetIndex === -1 &&
    (language === LANGUAGES.english || language === LANGUAGES.bilingual)
  ) {
    newEnAssets.push(updatedAsset);
  }

  if (frAssetIndex !== -1 && language === LANGUAGES.english) {
    newFrAssets.splice(frAssetIndex, 1);
  } else if (frAssetIndex !== -1) {
    newFrAssets[frAssetIndex] = updatedAsset;
  } else if (
    frAssetIndex === -1 &&
    (language === LANGUAGES.french || language === LANGUAGES.bilingual)
  ) {
    newFrAssets.push(updatedAsset);
  }

  return {
    enAssets: newEnAssets,
    frAssets: newFrAssets,
  };
};

const buildCombinedAssetList = (currentAssets, enAssets, frAssets) => {
  // If an asset has been removed from both underlying asset lists (ie: asset was deleted), remove it from the currentAsset list
  for (let i = currentAssets.length - 1; i >= 0; i -= 1) {
    const enIndex = findIndex(
      propEq("displayIndex", currentAssets[i].displayIndex),
      enAssets
    );
    const frIndex = findIndex(
      propEq("displayIndex", currentAssets[i].displayIndex),
      frAssets
    );

    if (enIndex === -1 && frIndex === -1) {
      currentAssets.splice(i, 1);
    }
  }

  const updateCurrentAssets = (subAssetList) => {
    for (let i = 0; i < subAssetList.length; i += 1) {
      const subAsset = subAssetList[i];
      const index = findIndex(
        propEq("displayIndex", subAsset.displayIndex),
        currentAssets
      );

      if (index !== -1) {
        for (let n = 0; n < currentAssets[index].renditions.length; n += 1) {
          // When updating the list of assets displays on the page, we want to
          // mutate the assets rather than generating new object to prevent UI weirdness
          /* eslint-disable no-param-reassign */
          currentAssets[index].isPublished = subAsset.isPublished;
          currentAssets[index].caption = subAsset.caption;
          currentAssets[index].renditions[n].language =
            subAsset.renditions[n].language;
          currentAssets[index].renditions[n].devices =
            subAsset.renditions[n].devices;
          /* eslint-enable no-param-reassign */
        }
      } else {
        currentAssets.push(subAsset);
      }
    }
  };

  updateCurrentAssets(enAssets);
  updateCurrentAssets(frAssets);

  return sortBy(prop("displayIndex"), currentAssets);
};

const removeDevice = (assetList, indexToUpdate, renditionToUpdate, device) => {
  const newAssets = assetList;

  const assetIndex = findIndex(
    propEq("displayIndex", indexToUpdate),
    newAssets
  );

  if (assetIndex !== -1) {
    newAssets[assetIndex].renditions[renditionToUpdate].devices = reject(
      equals(device),
      newAssets[assetIndex].renditions[renditionToUpdate].devices
    );
  }

  return newAssets;
};

const doesAssetContainDevice = curry((rendition, device) => {
  return rendition.devices && rendition.devices.includes(device);
});

const isAssetAlreadySet = (enAssets, frAssets, newAsset) => {
  const getContentPaths = pluck("contentPath");

  const enContentPaths = getContentPaths(enAssets);
  const frContentPaths = getContentPaths(frAssets);

  return (
    enContentPaths.includes(newAsset.contentPath) ||
    frContentPaths.includes(newAsset.contentPath)
  );
};

export {
  ACTION,
  AEM_LANGUAGES,
  LANGUAGES,
  DEVICES,
  buildAssetPickerUrl,
  buildCombinedAssetList,
  doesAssetContainDevice,
  getAssetLanguage,
  getCropForDevice,
  getDefaultDevices,
  isAssetAlreadySet,
  removeDevice,
  updateAssetArrays,
};
