import React from "react";
import PropTypes from "prop-types";
import {
  Asset,
  CopyButton,
  Flex,
  Form,
  FormControl,
  IconButton,
  Note,
  TextInput,
  TextLink,
  Tooltip,
} from "@contentful/f36-components";
import { CycleIcon, ErrorCircleIcon } from "@contentful/f36-icons";

import Config from "../config";

const UnpublishedAssetDisplay = ({ assetMeta, isV2Enabled, refreshFn }) => {
  const publishUrl = `${Config.aemDispatcherHost}/assets.html/${assetMeta.contentPath}`;

  return (
    <>
      {isV2Enabled && (
        <Flex alignItems="center">
          <ErrorCircleIcon variant="negative" />
          <div className="unpublished-message">Asset not published in DAM.</div>
          <TextLink
            className="unpublished-link"
            href={publishUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Publish Now
          </TextLink>
          <Tooltip content="Refresh Asset Display">
            <IconButton
              variant="transparent"
              icon={<CycleIcon variant="Secondary" />}
              onClick={() => {
                refreshFn(assetMeta);
              }}
            />
          </Tooltip>
        </Flex>
      )}
      {!isV2Enabled && (
        <>
          <Note variant="warning" className="note">
            The asset does not appear to be published. Please publish the asset
            in the DAM.
          </Note>

          <div className="asset-display">
            <div className="asset-details">
              <Form className="unpublished-asset-form">
                <FormControl id="name">
                  <FormControl.Label>Name</FormControl.Label>
                  <TextInput name="name" value={assetMeta.name} isDisabled />
                </FormControl>

                <FormControl id="contentPath">
                  <FormControl.Label>Content Path</FormControl.Label>
                  <TextInput
                    name="contentPath"
                    value={assetMeta.contentPath}
                    isDisabled
                  />
                  <CopyButton />
                </FormControl>
              </Form>
            </div>
            <div className="asset-preview">
              <Asset
                status="archived"
                src=""
                title=""
                className="unpublished-asset-icon"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

UnpublishedAssetDisplay.defaultProps = {
  isV2Enabled: false,
  refreshFn: undefined,
};

UnpublishedAssetDisplay.propTypes = {
  assetMeta: PropTypes.objectOf(PropTypes.any).isRequired,
  isV2Enabled: PropTypes.bool,
  refreshFn: PropTypes.func,
};

export default UnpublishedAssetDisplay;
