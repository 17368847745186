export const specialCharacters = [
  { code: 180, desc: 'acute accent' },
  { code: 38, desc: 'ampersand' },
  { code: 166, desc: 'broken vertical bar' },
  { code: 8226, desc: 'bullet' },
  { code: 184, desc: 'cedilla' },
  { code: 162, desc: 'cent' },
  { code: 169, desc: 'copyright' },
  { code: 176, desc: 'degree' },
  { code: 247, desc: 'division' },
  { code: 189, desc: 'fraction half' },
  { code: 188, desc: 'fraction quarter' },
  { code: 190, desc: 'fraction three quarters' },
  { code: 62, desc: 'greater than' },
  { code: 161, desc: 'inverted exclamation mark' },
  { code: 191, desc: 'inverted question mark' },
  { code: 171, desc: 'left-pointing double angle quotation mark' },
  { code: 60, desc: 'less than' },
  { code: 175, desc: 'macron' },
  { code: 181, desc: 'micro' },
  { code: 160, desc: 'non-breaking space' },
  { code: 172, desc: 'not' },
  { code: 182, desc: 'paragraph' },
  { code: 177, desc: 'plus-minus' },
  { code: 34, desc: 'quotation mark' },
  { code: 174, desc: 'registered' },
  { code: 187, desc: 'right-pointing double angle quotation mark' },
  { code: 167, desc: 'section' },
  { code: 168, desc: 'umlaut/diaeresis' },
  { code: 215, desc: 'multiplication' },
  { code: 8482, desc: 'trade mark' },
  { code: 8364, desc: 'euro' },
  { code: 163, desc: 'pound' },
  { code: 165, desc: 'yen' },
  { code: 8222, desc: 'double low-9 quotation mark' },
  { code: 710, desc: 'modifier circumflex accent' },
  { code: 8224, desc: 'dagger' },
  { code: 8225, desc: 'double dagger' },
  { code: 8230, desc: 'horizontal ellipsis' },
  { code: 8220, desc: 'left double quotation mark' },
  { code: 8249, desc: 'single left-pointing angle quotation mark' },
  { code: 8216, desc: 'left single quotation mark' },
  { code: 183, desc: 'middle dot' },
  { code: 8212, desc: 'em dash' },
  { code: 8211, desc: 'en dash' },
  { code: 8240, desc: 'per mille' },
  { code: 8250, desc: 'single right-pointing angle quotation mark' },
  { code: 8217, desc: 'right single quotation mark' },
  { code: 8218, desc: 'single low-9 quotation mark' },
  { code: 170, desc: 'feminine ordinal indicator' },
  { code: 186, desc: 'masculine ordinal indicator' },
  { code: 8221, desc: 'right double quotation mark' },
  { code: 732, desc: 'small tilde' },
  { code: 9829, desc: 'black heart' },
  { code: 9830, desc: 'diamond' },
];
