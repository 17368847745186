import React from "react";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import {
  Checkbox,
  Flex,
  Form,
  FormControl,
  Heading,
  Note,
  Spinner,
  Textarea,
  TextInput,
  TextLink,
  ValidationMessage,
} from "@contentful/f36-components";

import Config, { environment } from "../config";
import { fetchFolderMetadata } from "../util/fetch-metadata";
import { onConfigure } from "./on-configure";

const ASSETFUL_BASE_FOLDER =
  "/content/dam/loblaw-companies-limited/creative-assets/";

const AppConfig = ({ sdk }) => {
  const [configError, setConfigError] = React.useState(null);
  const [accessTokenErr, setAccessTokenErr] = React.useState(null);
  const [viewFullError, setViewFullError] = React.useState(false);
  const [isV2Enabled, setIsV2Enabled] = React.useState(false);
  const [allowV1, setAllowV1] = React.useState(true);
  const [accessToken, setAccessToken] = React.useState("");
  const [damStartFolder, setDamStartFolder] = React.useState("");
  const [isFolderValid, setIsFolderValid] = React.useState(true);
  const [isCheckingFolder, setIsCheckingFolder] = React.useState(false);

  // This method will be called when a user clicks on 'Install'
  // or 'Save' in the configuration screen.
  async function onConfig() {
    setConfigError(null);

    if (!isFolderValid) {
      return false;
    }

    const { space: spaceId, environment: environmentId } = sdk.ids;

    if (!accessToken || !spaceId) {
      setAccessTokenErr("Personal Access Token is required");
      return false;
    }
    setAccessTokenErr(null);

    try {
      return await onConfigure(
        Config,
        spaceId,
        environmentId,
        accessToken,
        isV2Enabled,
        damStartFolder
      );
    } catch (error) {
      setConfigError(error);
      sdk.notifier.error(
        "Failed to configure the Personalization Container content type."
      );
      return false;
    }
  }

  React.useEffect(() => {
    const loadData = async () => {
      const parameters = await sdk.app.getParameters();

      if (parameters) {
        setDamStartFolder(parameters.damStartFolder);
        setIsV2Enabled(parameters.isV2Enabled);

        // Disallow switching back to V1 in production master environments
        setAllowV1(
          !parameters.isV2Enabled ||
            (sdk.ids.environment !== "master" &&
              sdk.ids.environmentAlias !== "master") ||
            environment !== "prod"
        );
      }

      sdk.app.setReady();
    };

    loadData();

    return () => {};
  }, [sdk]);

  // `onConfigure` allows to configure a callback to be
  // invoked when a user attempts to install the app or update
  // its configuration.
  sdk.app.onConfigure(onConfig);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFolder = React.useCallback(
    debounce(async (folder) => {
      if (!folder) {
        setIsFolderValid(true);
        return;
      }
      setIsCheckingFolder(true);

      const folderMetadata = await fetchFolderMetadata(
        sdk,
        Config,
        `${ASSETFUL_BASE_FOLDER}${folder}`
      );

      if (folderMetadata) {
        setIsFolderValid(true);
      } else {
        setIsFolderValid(false);
      }

      setIsCheckingFolder(false);
    }, 200),
    [sdk]
  );

  return (
    <div className="app-config-page">
      <Note variant="primary" testId="cf-ui-note">
        Enter the required detials and click Install or Save to complete
        installation or upgrade
      </Note>
      <Form id="app-config">
        <Heading>{Config.appName}</Heading>

        <FormControl isRequired id="personal-access-token">
          <FormControl.Label>Personal Access Token</FormControl.Label>
          <TextInput
            name="personal-access-token"
            onChange={(e) => setAccessToken(e.target.value)}
            value={accessToken}
            type="password"
            required
          />
          <FormControl.HelpText>
            Please enter the access token
          </FormControl.HelpText>
          {accessTokenErr && (
            <FormControl.ValidationMessage>
              {accessTokenErr}
            </FormControl.ValidationMessage>
          )}
        </FormControl>

        <FormControl id="enable-2-0">
          <FormControl.Label>Enable DAM 2.0 Functionality</FormControl.Label>
          <Checkbox
            name="enable-2-0"
            isChecked={isV2Enabled}
            isDisabled={!allowV1}
            onChange={() => setIsV2Enabled(!isV2Enabled)}
          />
          <FormControl.HelpText>
            In Master environments, once version 2.0 functionality is enabled it
            cannot be disabled
          </FormControl.HelpText>
        </FormControl>

        {isV2Enabled && (
          <FormControl id="starting-folder">
            <FormControl.Label>Asset Picker Starting Folder</FormControl.Label>
            <Flex>
              <div
                style={{
                  minWidth: "100px",
                  alignSelf: "center",
                  color: "#555",
                }}
              >
                creative-assets/
              </div>
              <TextInput
                name="starting-folder"
                value={damStartFolder}
                isDisabled={!isV2Enabled}
                onChange={(e) => {
                  setDamStartFolder(e.target.value);
                  validateFolder(e.target.value);
                }}
              />
            </Flex>
            <FormControl.HelpText>
              Please enter the folder path after &quot;{ASSETFUL_BASE_FOLDER}
              &quot;
              {/* <TextLink
                target="_blank"
                href="https://confluence.lblw.cloud/pages/viewpage.action?pageId=555670693"
              >
                View Detailed instructions
              </TextLink> */}
            </FormControl.HelpText>
            {!isFolderValid && (
              <FormControl.ValidationMessage>
                Invalid Assetful Folder
              </FormControl.ValidationMessage>
            )}
            {isCheckingFolder && <Spinner />}
          </FormControl>
        )}
      </Form>

      {configError && (
        <>
          <ValidationMessage>
            <TextLink
              variant="negative"
              onClick={() => setViewFullError(!viewFullError)}
            >
              Installation eror: click to view the full error details
            </TextLink>
          </ValidationMessage>
          {viewFullError && (
            <Textarea
              aria-label="Error Details"
              value={configError.stack}
              disabled
              error
              rows={10}
            />
          )}
        </>
      )}
    </div>
  );
};

AppConfig.propTypes = {
  sdk: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AppConfig;
