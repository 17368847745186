import React from "react";
import PropTypes from "prop-types";
import {
  assoc,
  contains,
  curry,
  equals,
  find,
  findIndex,
  mergeRight,
  pluck,
  propEq,
  reject,
} from "ramda";
import {
  Button,
  Flex,
  FormControl,
  IconButton,
  Note,
  Spinner,
  Tooltip,
  TextInput,
} from "@contentful/f36-components";
import { HelpCircleIcon } from "@contentful/f36-icons";
import { CgCornerDownLeft } from "react-icons/cg";
import { IconContext } from "react-icons";

import "./index.css";

import Config from "../config";
import buildImageRenditionUrl from "./ImageRenditionUrl";
import AssetAccordian from "./AssetAccordian";
import ASSET_TYPE from "./common";
import { fetchAssetMetadata } from "../util/fetch-metadata";
import { getEntrySnapshots } from "../util/contentful-cma";
import {
  ACTION,
  AEM_LANGUAGES,
  DEVICES,
  LANGUAGES,
  buildAssetPickerUrl,
  buildCombinedAssetList,
  getDefaultDevices,
  isAssetAlreadySet,
  removeDevice,
  updateAssetArrays,
} from "./utils";

// Use a constant for empty array to avoid UI weirdness (I think, this was done early in development, maybe no longer needed?)
const emptyArray = [];

const typeForAsset = (mimeType, assetName) => {
  if (mimeType.includes("image")) {
    return ASSET_TYPE.IMAGE;
  }
  if (mimeType.includes("video")) {
    return ASSET_TYPE.VIDEO;
  }
  if (
    mimeType.includes("text/vtt") ||
    mimeType.includes("application/x-subrip") ||
    assetName.toLowerCase().endsWith(".sbv") ||
    assetName.toLowerCase().endsWith(".sub")
  ) {
    return ASSET_TYPE.CAPTION;
  }

  return ASSET_TYPE.OTHER;
};

const buildMeta = (assetData) => {
  const aemUrl = new URL(assetData.url);
  const type = typeForAsset(assetData.type, assetData.title);

  return {
    mimeType: assetData.type,
    name: assetData.title,
    contentPath: aemUrl.pathname,
    isPublished: false,
    type,
  };
};

const buildExtendedMeta = (extendedMeta) => {
  return {
    approvalStatus: extendedMeta.approvalStatus,
    expiryStatus: extendedMeta.expiryStatus,
    expiryDate: extendedMeta.expiryDate,
  };
};

const buildRenditions = (
  extendedMeta,
  originalAssetPublicUrl,
  language,
  devices
) => {
  return [
    {
      ...extendedMeta.originalAsset,
      url: originalAssetPublicUrl,
      generatedCropName: "Original",
      language,
      devices: devices.devices,
    },
    ...((extendedMeta.generatedRenditions &&
      extendedMeta.generatedRenditions.map(
        mergeRight({
          language,
          devices: [],
        })
      )) ||
      []),
    ...((extendedMeta.manualRenditions &&
      extendedMeta.manualRenditions.map(
        mergeRight({
          language,
          devices: [],
        })
      )) ||
      []),
    ...((extendedMeta.scene7Renditions &&
      extendedMeta.scene7Renditions.set &&
      extendedMeta.scene7Renditions.set.relation &&
      extendedMeta.scene7Renditions.set.relation.map((scene7Relation) => {
        return {
          path: scene7Relation.n,
          generatedCropName: scene7Relation.userdata.SmartCropDef,
          width: scene7Relation.userdata.SmartCropWidth,
          height: scene7Relation.userdata.SmartCropHeight,
          url: `${Config.dynamicMediaHost}/is/image/${scene7Relation.n}`,
          language,
          devices: [],
        };
      })) ||
      []),
  ];
};

const checkIfDocumentPublished = async (assetUrl) => {
  let result;
  try {
    result = await fetch(assetUrl);
  } catch (e) {
    console.error(`ERROR checking published status for ${assetUrl}:`, e);
    return false;
  }

  return result.ok;
};

const checkIfAssetIsPublished = async (assetUrl, type, mimeType) => {
  const now = new Date();
  const cacheBustingUrl = assetUrl.includes("?")
    ? `${assetUrl}&t=${now.valueOf()}`
    : `${assetUrl}?t=${now.valueOf()}`;
  return checkIfDocumentPublished(cacheBustingUrl, mimeType);
};

const buildPublicUrl = (fullMeta) => {
  const { type, contentPath } = fullMeta;

  if (type === ASSET_TYPE.VIDEO || type === ASSET_TYPE.CAPTION) {
    const videoFilename = contentPath.replace("/content/dam", "");
    return `${Config.dynamicMediaHost}/is/content/${Config.dynamicMediaTenant}${videoFilename}`;
  }
  if (type === ASSET_TYPE.IMAGE) {
    return buildImageRenditionUrl(fullMeta, fullMeta);
  }
  return `${Config.akamaiHost}${contentPath}`;
};

const getNextDisplayIndex = (enAssets, frAssets) => {
  const maxEnDisplayIndex = Math.max(...pluck("displayIndex", enAssets));
  const maxFrDisplayIndex = Math.max(...pluck("displayIndex", frAssets));
  return Math.max(maxEnDisplayIndex, maxFrDisplayIndex, 0) + 1;
};

const AssetFieldEditor = ({ sdk }) => {
  const { isV2Enabled } = sdk.parameters.installation;

  const [enLocale] = React.useState(
    find(contains("en"), sdk.locales.available)
  );
  const [frLocale] = React.useState(
    find(contains("fr"), sdk.locales.available)
  );

  const [enAssets, setEnAssets] = React.useState([]);
  const [frAssets, setFrAssets] = React.useState([]);
  const [assetsForDisplay, setAssetsForDisplay] = React.useState([]);
  const [assetIndexForCaption, setAssetIndexForCaption] = React.useState(null);

  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [popup, setPopup] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [localesBeingUpdated, setLocalesBeingUpdated] = React.useState([]);

  const [republishMessage, setRepublishMessage] = React.useState(false);
  const [pastedUrl, setPastedUrl] = React.useState("");
  const [pasteError, setPasteError] = React.useState(false);

  // This function updates the asset object stored in Contentful
  // This should be the only place that updates the Contentful data
  const updateAssetField = React.useCallback(
    async (newEnAssets, newFrAssets) => {
      const locales = [];
      if (newEnAssets) {
        locales.push(enLocale);
      }
      if (newFrAssets) {
        locales.push(frLocale);
      }
      // Store which locales have updates, this is used later when the listener
      // on the Contentful data updates the React state
      setLocalesBeingUpdated(locales);

      if (newEnAssets) {
        await sdk.entry.fields.asset.setValue(newEnAssets, enLocale);
      }

      if (newFrAssets) {
        await sdk.entry.fields.asset.setValue(newFrAssets, frLocale);
      }
    },
    [sdk, enLocale, frLocale]
  );

  const getAssetFullMetadata = React.useCallback(
    async (contentPath) => {
      const fetchedExtendedMeta = await fetchAssetMetadata(
        sdk,
        Config,
        contentPath
      );
      if (!fetchedExtendedMeta) {
        return undefined;
      }

      const fileNameIndex = contentPath.lastIndexOf("/");
      const name = contentPath.slice(fileNameIndex + 1);
      const type = typeForAsset(fetchedExtendedMeta.mimeType, name);
      const fullMeta = {
        ...fetchedExtendedMeta,
        name,
        type,
      };

      return fullMeta;
    },
    [sdk]
  );

  // Pulls the latest asset info from Assetful and updates
  // the asset within Contentful based off this info
  const refreshAssetFromAem = React.useCallback(
    async (asset) => {
      const fullMeta = await getAssetFullMetadata(asset.contentPath);

      const publicUrl = buildPublicUrl(fullMeta);
      const resizedUrl =
        asset.type === ASSET_TYPE.IMAGE
          ? `${publicUrl}?imwidth=600`
          : publicUrl;

      // Verify if asset is published by using a resized version rather than
      // the original to decrease response time on determining asset publication.
      const isPublished = await checkIfAssetIsPublished(
        resizedUrl,
        asset.type,
        asset.mimeType
      );

      let assetLanguage = LANGUAGES.bilingual;
      if (fullMeta.language === AEM_LANGUAGES.english) {
        assetLanguage = LANGUAGES.english;
      } else if (fullMeta.language === AEM_LANGUAGES.french) {
        assetLanguage = LANGUAGES.french;
      }

      const getRenditionByName = (generatedCropName) => {
        return find(
          propEq("generatedCropName", generatedCropName),
          asset.renditions
        );
      };

      // It feels like there should be some way to simplify/merge this with the
      // "buildRenditions" function as they are very similar
      const updatedRenditions = [
        {
          ...fullMeta.originalAsset,
          url: publicUrl,
          generatedCropName: "Original",
          language: asset.renditions[0].language,
          devices: asset.renditions[0].devices,
        },
        ...((fullMeta.generatedRenditions &&
          fullMeta.generatedRenditions.map((rendition) => {
            const existingRendition = getRenditionByName(
              rendition.generatedCropName
            );

            return {
              ...rendition,
              language: existingRendition
                ? existingRendition.language
                : assetLanguage,
              devices: existingRendition ? existingRendition.devices : [],
            };
          })) ||
          []),
        ...((fullMeta.manualRenditions &&
          fullMeta.manualRenditions.map((rendition) => {
            const existingRendition = getRenditionByName(
              rendition.generatedCropName
            );

            return {
              ...rendition,
              language: existingRendition
                ? existingRendition.language
                : assetLanguage,
              devices: existingRendition ? existingRendition.devices : [],
            };
          })) ||
          []),
        ...((fullMeta.scene7Renditions &&
          fullMeta.scene7Renditions.set &&
          fullMeta.scene7Renditions.set.relation &&
          fullMeta.scene7Renditions.set.relation.map((scene7Relation) => {
            const existingRendition = getRenditionByName(
              scene7Relation.generatedCropName
            );

            return {
              path: scene7Relation.n,
              generatedCropName: scene7Relation.userdata.SmartCropDef,
              width: scene7Relation.userdata.SmartCropWidth,
              height: scene7Relation.userdata.SmartCropHeight,
              url: `${Config.dynamicMediaHost}/is/image/${scene7Relation.n}`,
              language: existingRendition
                ? existingRendition.language
                : assetLanguage,
              devices: existingRendition ? existingRendition.devices : [],
            };
          })) ||
          []),
      ];

      return {
        ...asset,
        url: publicUrl,
        isPublished,
        renditions: updatedRenditions,
      };
    },
    [getAssetFullMetadata]
  );

  // When a new asset is selected from the AEM asset picker
  // this is called and adds that asset to Contentful
  const validateAndSetValue = React.useCallback(
    async (fullMeta, languageOverride) => {
      const publicUrl = buildPublicUrl(fullMeta);
      const resizedUrl =
        fullMeta.type === ASSET_TYPE.IMAGE
          ? `${publicUrl}?imwidth=600`
          : publicUrl;

      let assetLanguage = LANGUAGES.bilingual;
      if (fullMeta.language === AEM_LANGUAGES.english) {
        assetLanguage = LANGUAGES.english;
      } else if (fullMeta.language === AEM_LANGUAGES.french) {
        assetLanguage = LANGUAGES.french;
      }

      // Verify if asset is published by using a resized version rather than
      // the original to decrease response time on determining asset publication.
      const isPublished = await checkIfAssetIsPublished(
        resizedUrl,
        fullMeta.type,
        fullMeta.mimeType
      );

      const generateAssets = (currentAssets, displayIndex, defaultDevices) => {
        return [
          ...currentAssets,
          {
            contentPath: fullMeta.contentPath,
            mimeType: fullMeta.mimeType,
            name: fullMeta.name,
            type: fullMeta.type,
            url: publicUrl,
            isPublished,
            displayIndex,
            renditions: buildRenditions(
              fullMeta,
              publicUrl,
              languageOverride || defaultDevices.language,
              defaultDevices
            ),
            extendedMeta: buildExtendedMeta(fullMeta),
            ...(fullMeta &&
              fullMeta.scene7FileAVS && {
                scene7FileAVS: fullMeta.scene7FileAVS,
              }),
          },
        ];
      };
      const currentEnAsset = sdk.entry.fields.asset.getValue(enLocale) || [];
      const currentFrAsset = sdk.entry.fields.asset.getValue(frLocale) || [];
      const displayIndex = getNextDisplayIndex(
        currentEnAsset,
        currentFrAsset,
        assetLanguage
      );
      const defaultDevices = getDefaultDevices(
        currentEnAsset,
        currentFrAsset,
        assetLanguage
      );

      let newEnAssets;
      // if (languageOverride is English) OR (asset metadata is English/Bilingual and languageOverride isn't French)
      if (
        (languageOverride && languageOverride === LANGUAGES.english) ||
        ((assetLanguage === LANGUAGES.english ||
          assetLanguage === LANGUAGES.bilingual) &&
          languageOverride !== LANGUAGES.french)
      ) {
        newEnAssets = generateAssets(
          currentEnAsset,
          displayIndex,
          defaultDevices
        );
      }

      let newFrAssets;
      // if (languageOverride is French) OR (asset metadata is French/Bilingual and languageOverride isn't English)
      if (
        (languageOverride && languageOverride === LANGUAGES.french) ||
        ((assetLanguage === LANGUAGES.french ||
          assetLanguage === LANGUAGES.bilingual) &&
          languageOverride !== LANGUAGES.english)
      ) {
        newFrAssets = generateAssets(
          currentFrAsset,
          displayIndex,
          defaultDevices
        );
      }

      await updateAssetField(newEnAssets, newFrAssets);
    },
    [sdk, updateAssetField, enLocale, frLocale]
  );

  const updateFieldOnNewSelectionCaption = React.useCallback(
    async (captionMetaValue) => {
      const publicUrl = buildPublicUrl(captionMetaValue);

      const isPublished = await checkIfAssetIsPublished(
        publicUrl,
        captionMetaValue.type,
        captionMetaValue.mimeType
      );

      let captionUrl = null;
      if (isPublished) {
        captionUrl = publicUrl;
      }

      const newValue = { ...captionMetaValue, captionUrl };

      const findAssetIndex = findIndex(
        propEq("displayIndex", assetIndexForCaption)
      );
      const addCaptionToAsset = (asset) => {
        if (asset.displayIndex === assetIndexForCaption) {
          return assoc("caption", newValue, asset);
        }

        return asset;
      };

      let newEnAssets;
      let newFrAssets;
      const enAssetIndex = findAssetIndex(enAssets);
      if (enAssetIndex !== -1) {
        newEnAssets = enAssets.map(addCaptionToAsset);
      }

      const frAssetIndex = findAssetIndex(frAssets);
      if (frAssetIndex !== -1) {
        newFrAssets = frAssets.map(addCaptionToAsset);
      }

      await updateAssetField(newEnAssets, newFrAssets);
    },
    [updateAssetField, assetIndexForCaption, enAssets, frAssets]
  );

  // caption vs standard asset meta information
  const updateFieldOnNewSelection = React.useCallback(
    async (newMetaValue) => {
      if (newMetaValue.type === ASSET_TYPE.CAPTION) {
        await updateFieldOnNewSelectionCaption(newMetaValue);
      } else {
        const fullMeta = await getAssetFullMetadata(newMetaValue.contentPath);
        await validateAndSetValue(fullMeta);
      }
    },
    [
      updateFieldOnNewSelectionCaption,
      validateAndSetValue,
      getAssetFullMetadata,
    ]
  );

  const handleAssetSelectorEvent = React.useCallback(
    (event) => {
      if (typeof event.data === "string") {
        const parsedData = JSON.parse(event.data);

        if (
          parsedData &&
          Object.entries(parsedData).length !== 0 &&
          parsedData.config &&
          parsedData.config.action !== "close"
        ) {
          const loadAssets = async (assetData) => {
            setIsLoading(true);

            for (let i = 0; i < assetData.data.length; i += 1) {
              const newMetaValue = buildMeta(assetData.data[i]);

              if (!isAssetAlreadySet(enAssets, frAssets, newMetaValue)) {
                // loop over awaits due to parralel processing not playing well with the
                // way the hook dependancies are currently set up, trying to process in
                // parallel will cause a race condition - possible to rework but timely
                // eslint-disable-next-line no-await-in-loop
                await updateFieldOnNewSelection(newMetaValue);
              } else {
                sdk.notifier.warning(
                  `Asset ${newMetaValue.name} is already selected for this entry.`
                );
              }
            }

            setIsLoading(false);
          };

          loadAssets(parsedData);

          popup.close();
        }
      }
    },
    [enAssets, frAssets, popup, updateFieldOnNewSelection, sdk.notifier]
  );

  // Need to ignore deps due to curry'ing the function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFullAsset = React.useCallback(
    curry(async (locale, assetValue) => {
      if (locale === enLocale) {
        setEnAssets(assetValue || emptyArray);
      } else if (locale === frLocale) {
        setFrAssets(assetValue || emptyArray);
      }

      setLocalesBeingUpdated((locales) => {
        return reject(equals(locale), locales);
      });
    }),
    [enLocale, frLocale]
  );

  React.useEffect(() => {
    if (localesBeingUpdated && localesBeingUpdated.length === 0) {
      setAssetsForDisplay((currentAssets) =>
        buildCombinedAssetList(currentAssets, enAssets, frAssets)
      );
    }
  }, [enAssets, frAssets, localesBeingUpdated]);

  React.useEffect(() => {
    const unsubFns = [];

    if (!isInitialLoad) {
      unsubFns.push(
        sdk.entry.fields.asset.onValueChanged(
          enLocale,
          updateFullAsset(enLocale)
        )
      );
      unsubFns.push(
        sdk.entry.fields.asset.onValueChanged(
          frLocale,
          updateFullAsset(frLocale)
        )
      );
    }

    return () => {
      for (let i = 0; i < unsubFns.length; i += 1) {
        unsubFns[i]();
      }
    };
  }, [sdk, enLocale, frLocale, isInitialLoad, updateFullAsset]);

  React.useEffect(() => {
    window.addEventListener("message", handleAssetSelectorEvent);

    return () => {
      window.removeEventListener("message", handleAssetSelectorEvent);
    };
  }, [handleAssetSelectorEvent]);

  const checkLatestPublishedAsset = async () => {
    const currentEnAssets = sdk.entry.fields.asset.getValue(enLocale);
    const currentFrAssets = sdk.entry.fields.asset.getValue(frLocale);
    if (
      (currentEnAssets && currentEnAssets.length === 0) ||
      (currentFrAssets && currentFrAssets.length === 0) ||
      (sdk.ids.environment !== "master" &&
        sdk.ids.environmentAlias !== "master") // Snapshots only exist in master, so skip this step if in another environment
    ) {
      return;
    }

    const publishedVersions = await getEntrySnapshots(sdk, sdk.ids.entry);
    if (publishedVersions.items.length === 0) {
      return;
    }

    const latestSnapshot = publishedVersions.items.reduce(
      (topItem, currentItem) => {
        if (
          topItem &&
          topItem.snapshot.sys.publishedVersion >
            currentItem.snapshot.sys.publishedVersion
        ) {
          return topItem;
        }
        return currentItem;
      },
      undefined
    );

    const checkIfRepublishNeeded = curry((locale, republishNeeded, asset) => {
      if (republishNeeded) return republishNeeded;

      const getRenditionByName = (renditions, generatedCropName) => {
        return find(propEq("generatedCropName", generatedCropName), renditions);
      };

      const isV1Snapshot = !!latestSnapshot.snapshot.fields.assetMeta;

      const latestPublishedAsset = isV1Snapshot
        ? latestSnapshot.snapshot.fields.asset[locale]
        : latestSnapshot.snapshot.fields.asset[locale].find(
            propEq("displayIndex", asset.displayIndex)
          );

      return asset.renditions.reduce((hasRenditionChanged, rendition) => {
        if (hasRenditionChanged || rendition.devices.length === 0)
          return hasRenditionChanged;

        if (isV1Snapshot) {
          // If the current rendition is the selected rendition
          if (
            rendition.devices.includes(DEVICES.desktop) &&
            ((rendition.language === LANGUAGES.english &&
              locale === enLocale) ||
              (rendition.language === LANGUAGES.french && locale === frLocale))
          ) {
            return (
              latestPublishedAsset.name === asset.name &&
              latestPublishedAsset.url !== rendition.url
            );
          }
        } else {
          const oldRendition = getRenditionByName(
            latestPublishedAsset.renditions,
            rendition.generatedCropNames
          );

          return (
            oldRendition &&
            oldRendition.url !== rendition.url &&
            oldRendition.generatedCropName === rendition.generatedCropName
          );
        }

        return false;
      }, false);
    });

    let isRepublishNeeded = false;
    if (currentEnAssets && currentEnAssets.length > 0) {
      isRepublishNeeded = currentEnAssets.reduce(
        checkIfRepublishNeeded(enLocale),
        isRepublishNeeded
      );
    }

    if (currentFrAssets && currentFrAssets.length > 0) {
      isRepublishNeeded = currentFrAssets.reduce(
        checkIfRepublishNeeded(frLocale),
        isRepublishNeeded
      );
    }

    if (isRepublishNeeded) {
      setRepublishMessage(isRepublishNeeded);
    }
  };

  React.useEffect(() => {
    const confirmAssetMeta = async () => {
      setIsLoading(true);

      /// ////////////////////
      // Check if this is a V1 data structure and convert to V2 if needed
      /// ////////////////////
      const enAssetMeta = sdk.entry.fields.assetMeta.getValue(enLocale);
      const frAssetMeta = sdk.entry.fields.assetMeta.getValue(frLocale);
      if (enAssetMeta || frAssetMeta) {
        await sdk.entry.fields.asset.removeValue(enLocale);
        await sdk.entry.fields.asset.removeValue(frLocale);

        if (enAssetMeta) {
          const fullMeta = await getAssetFullMetadata(enAssetMeta.contentPath);
          await validateAndSetValue(fullMeta, LANGUAGES.english);
        }

        if (frAssetMeta) {
          const fullMeta = await getAssetFullMetadata(frAssetMeta.contentPath);
          await validateAndSetValue(fullMeta, LANGUAGES.french);
        }

        await sdk.entry.fields.assetMeta.removeValue(enLocale);
        await sdk.entry.fields.assetMeta.removeValue(frLocale);
      }

      /// ////////////////////
      // Update the assets with any new changes from AEM
      /// ////////////////////
      const englishAssets = sdk.entry.fields.asset.getValue(enLocale);
      const frenchAssets = sdk.entry.fields.asset.getValue(frLocale);

      let updatedEnglishAssets;
      let updatedFrenchAssets;
      if (englishAssets && englishAssets.length > 0) {
        updatedEnglishAssets = await Promise.all(
          englishAssets.map(refreshAssetFromAem)
        );
      }
      if (frenchAssets && frenchAssets.length > 0) {
        updatedFrenchAssets = await Promise.all(
          frenchAssets.map(refreshAssetFromAem)
        );
      }

      await updateAssetField(updatedEnglishAssets, updatedFrenchAssets);

      await checkLatestPublishedAsset();
      setIsLoading(false);
      setIsInitialLoad(false);
    };

    confirmAssetMeta();
    // Disable exhaustive-deps because we explicitly want to only run this effect on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAssetPicker = React.useCallback(
    (displayIndex) => {
      // If a displayIndex is being passed, we are selecting a caption for a specific asset
      // in this case, keep track of the index to be able to link post-caption selection
      if (displayIndex) {
        setAssetIndexForCaption(displayIndex);
      }

      const windowOpen = window.open(
        buildAssetPickerUrl(true, sdk.parameters.installation.damStartFolder),
        "Adobe DAM Asset Picker"
      );
      setPopup(windowOpen);
    },
    [sdk.parameters.installation.damStartFolder]
  );

  const deleteAsset = React.useCallback(
    async (indexToDelete) => {
      setIsLoading(true);

      await updateAssetField(
        reject(propEq("displayIndex", indexToDelete), enAssets),
        reject(propEq("displayIndex", indexToDelete), frAssets)
      );

      setIsLoading(false);
    },
    [enAssets, frAssets, updateAssetField]
  );

  const updateCaptionFieldOnClear = React.useCallback(
    async (assetIndex) => {
      const removeCaption = (asset) => {
        if (asset.displayIndex !== assetIndex) {
          return asset;
        }

        return {
          ...asset,
          caption: null,
        };
      };

      const newEnAssets = enAssets.map(removeCaption);
      const newFrAssets = frAssets.map(removeCaption);

      await updateAssetField(newEnAssets, newFrAssets);
    },
    [enAssets, frAssets, updateAssetField]
  );

  // I hate how this works but the logic to update the asset lists has been written from scratch multiple times
  // trying to get it set up well and at this point it just needs to work regardless of the complexity/readability
  const updateAssets = React.useCallback(
    async (indexToUpdate, renditionToUpdate, language, deviceChange) => {
      let newEnAssets = enAssets;
      let newFrAssets = frAssets;
      if (deviceChange.action === ACTION.remove) {
        newEnAssets = removeDevice(
          enAssets,
          indexToUpdate,
          renditionToUpdate,
          deviceChange.device
        );
        newFrAssets = removeDevice(
          frAssets,
          indexToUpdate,
          renditionToUpdate,
          deviceChange.device
        );
      }

      const updatedLists = updateAssetArrays(
        newEnAssets,
        newFrAssets,
        indexToUpdate,
        renditionToUpdate,
        language,
        deviceChange.action === ACTION.add ? deviceChange.device : undefined
      );

      await updateAssetField(updatedLists.enAssets, updatedLists.frAssets);
    },
    [enAssets, frAssets, updateAssetField]
  );

  const refreshAsset = React.useCallback(
    async (asset) => {
      setIsLoading(true);

      const refresh = (innerAsset) => {
        if (innerAsset.displayIndex === asset.displayIndex) {
          return refreshAssetFromAem(innerAsset);
        }

        return innerAsset;
      };

      const newEnAssets = await Promise.all(enAssets.map(refresh));
      const newFrAssets = await Promise.all(frAssets.map(refresh));

      await updateAssetField(newEnAssets, newFrAssets);

      setIsLoading(false);
    },
    [enAssets, frAssets, refreshAssetFromAem, updateAssetField]
  );

  const handlePastedUrl = async () => {
    setPasteError(false);
    if (!pastedUrl || pastedUrl.length === 0) {
      return;
    }

    const contentIndex = pastedUrl.indexOf("/content");
    if (contentIndex === -1) {
      setPasteError(true);
      return;
    }
    setIsLoading(true);

    const contentPath = pastedUrl.slice(contentIndex);
    const fullMeta = await getAssetFullMetadata(contentPath);

    if (!fullMeta) {
      setPasteError(true);
      setIsLoading(false);
      return;
    }

    await validateAndSetValue(fullMeta);

    setPastedUrl("");
    setIsLoading(false);
  };

  return (
    <div className="asset-selector">
      <Tooltip
        placement="bottom"
        id="tooltip"
        targetWrapperClassName="tooltip"
        content={
          <>
            You may create DAM asset entries by either:
            <br />
            <ul>
              <li>Going through the folder path</li>
              <li>Pasting the link to designated DAM asset</li>
            </ul>
          </>
        }
      >
        <HelpCircleIcon />
      </Tooltip>

      {assetsForDisplay.length > 0 &&
        assetsForDisplay.map((asset) => (
          <AssetAccordian
            key={asset.displayIndex}
            isLoading={isLoading}
            isV2Enabled={isV2Enabled}
            asset={asset}
            updateAsset={updateAssets}
            deleteAsset={deleteAsset}
            openAssetPicker={openAssetPicker}
            updateCaptionFieldOnClear={updateCaptionFieldOnClear}
            refreshFn={refreshAsset}
          />
        ))}
      <Flex>
        <Button
          variant="primary"
          isFullWidth={false}
          onClick={() => openAssetPicker()}
          isDisabled={isLoading}
          className="action-button asset-button"
          data-test-name="select-asset-button"
        >
          Select DAM Assets
        </Button>
        <div className="orWrapper">
          <div className="line" />
          <div className="wordwrapper">
            <div className="word">Or</div>
          </div>
        </div>
        <div className="url-wrapper">
          <TextInput
            placeholder="Paste the link to the DAM Asset"
            className="url-input"
            onChange={(e) => setPastedUrl(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handlePastedUrl();
              }
            }}
            value={pastedUrl}
            type="text"
            isDisabled={isLoading}
            isInvalid={pasteError}
          />
          <IconButton
            className="paste-button"
            variant="primary"
            onClick={handlePastedUrl}
            icon={
              <IconContext.Provider value={{ className: "paste-icon" }}>
                <CgCornerDownLeft />
              </IconContext.Provider>
            }
            isDisabled={isLoading}
          />
        </div>
      </Flex>
      {pasteError && (
        <FormControl.ValidationMessage className="paste-error-msg">
          Invalid Asset URL
        </FormControl.ValidationMessage>
      )}
      {republishMessage && (
        <Note variant="warning" className="note">
          An assets&apos; quality has been updated in Assetful. Please
          &quot;Publish changes&quot; for this entry to ensure the best asset is
          shown to users.
        </Note>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

AssetFieldEditor.propTypes = {
  sdk: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AssetFieldEditor;
