import * as React from "react";
import PropTypes from "prop-types";

import {
  TextInput,
  Paragraph,
  Flex,
  Radio,
  ValidationMessage,
} from "@contentful/f36-components";

const AltTextFieldEditor = ({ sdk }) => {
  const locales = sdk.locales.available;

  const [altText, setAltText] = React.useState(() => {
    const altText2 = {};
    locales.forEach((locale) => {
      const extValue = sdk.entry.fields.altText.getValue(locale);
      altText2[locale] = extValue || "";
    });

    return altText2;
  });

  const optionsField = sdk.field;
  const [hasAltText, setHasAltText] = React.useState(() => {
    let value = true;
    if (typeof optionsField.getValue() === "undefined") {
      optionsField.setValue(value); // default value
    } else {
      value = optionsField.getValue();
    }
    return value;
  });

  const onAltTextChangeHandler = async ({ target }) => {
    const locale = target.id;
    const { value } = target;

    // updateSdkFieldValue(locale, value);
    const altText3 = {};

    locales.forEach((lang) => {
      altText3[lang] = altText[lang];
    });

    altText3[locale] = value;

    setAltText(altText3);
    sdk.entry.fields.altText.setValue(value, locale);
  };

  const onHasAltTextChangeHandler = ({ target }) => {
    const selectedYes = target.value === "yes";

    setHasAltText(selectedYes);
    optionsField.setValue(selectedYes);
  };

  const getLocalName = (locale) => {
    return sdk.locales.names[locale];
  };

  return (
    <div className="asset-details">
      <Flex flexDirection="column" gap="spacingXs">
        <Radio
          isChecked={hasAltText}
          value="yes"
          onChange={onHasAltTextChangeHandler}
          name="abstractOption"
          id="yesCheckbox"
        >
          Yes
        </Radio>
        <Radio
          isChecked={!hasAltText}
          value="no"
          onChange={onHasAltTextChangeHandler}
          name="abstractOption"
          id="noCheckbox"
        >
          No, this image is purely decorative and provides no additional content
          to the user
        </Radio>
      </Flex>

      {hasAltText &&
        locales.map((locale) => (
          <React.Fragment key={locale}>
            <Paragraph>Alt Text (Required) - {getLocalName(locale)} </Paragraph>
            <TextInput
              id={locale}
              isInvalid={altText[locale].length < 1}
              onChange={onAltTextChangeHandler}
              value={altText[locale]}
              maxLength="256"
            />
            <Paragraph as="p">
              {altText[locale].length} characters
              <span style={{ float: "right" }}> Maximum 256 characters</span>
            </Paragraph>
            {altText[locale].length < 1 && (
              <ValidationMessage
                className="validation-message"
                testId="cf-ui-validation-message"
              >
                Required - Alt Text can not be empty
              </ValidationMessage>
            )}
            <br />
          </React.Fragment>
        ))}
    </div>
  );
};

AltTextFieldEditor.propTypes = {
  sdk: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AltTextFieldEditor;
