import React from "react";
import PropTypes from "prop-types";
import { Form, FormControl, TextInput } from "@contentful/f36-components";

const friendlyStatuses = {
  "not-expired": "Not Expired",
  expired: "Expired",
  draft: "Draft",
};

function friendlyDate(utcDate) {
  if (!utcDate) return "(none)";
  const date = new Date(utcDate);
  return date.toLocaleString();
}

function buildExpiryWarning(utcDate) {
  if (!utcDate) return undefined;

  const date = new Date(utcDate);
  const thirtyDays = new Date();
  thirtyDays.setDate(thirtyDays.getDate() + 30);
  const now = new Date();

  if (date > thirtyDays) return undefined;
  if (date < thirtyDays && date > now) return "Expires in the next 30 days";
  return "Expired";
}

const ExtendedMeta = ({ extendedMeta }) => {
  const expiryStatus = friendlyStatuses[extendedMeta.expiryStatus] || "";
  const approvalStatus = friendlyStatuses[extendedMeta.approvalStatus] || "";
  const expiryDate = friendlyDate(extendedMeta.expiryDate);
  const expiryWarning = buildExpiryWarning(extendedMeta.expiryDate);

  return (
    <Form className="extended-meta-form">
      <FormControl id="approvalStatus">
        <FormControl.Label>Approval Status</FormControl.Label>
        <TextInput name="approvalStatus" value={approvalStatus} isDisabled />
      </FormControl>

      <FormControl id="expiryStatus">
        <FormControl.Label>Expiry Status</FormControl.Label>
        <TextInput name="expiryStatus" value={expiryStatus} isDisabled />
      </FormControl>

      <FormControl id="expiryDate" isInvalid={expiryWarning !== undefined}>
        <FormControl.Label>Expiry Date</FormControl.Label>
        <TextInput name="expiryDate" value={expiryDate} isDisabled />
        {expiryWarning !== undefined && (
          <FormControl.ValidationMessage>
            {expiryWarning}
          </FormControl.ValidationMessage>
        )}
      </FormControl>
    </Form>
  );
};

ExtendedMeta.propTypes = {
  extendedMeta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ExtendedMeta;
