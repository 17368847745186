import React from "react";
import PropTypes from "prop-types";
import { CgScreen, CgSmartphone, CgTouchpad } from "react-icons/cg";
import { IconContext } from "react-icons";

const DEVICES = {
  desktop: "desktop",
  mobile: "mobile",
  tablet: "tablet",
};

const DeviceIcons = ({ doesAssetContainDevice, containerClass, iconClass }) => {
  return (
    <>
      <div
        className={`desktop-icon-container ${containerClass} ${
          doesAssetContainDevice(DEVICES.desktop) ? "" : "hidden"
        }`}
      >
        <IconContext.Provider
          value={{ className: `desktop-icon ${iconClass}` }}
        >
          <CgScreen />
        </IconContext.Provider>
      </div>
      <div
        className={`tablet-icon-container ${containerClass} ${
          doesAssetContainDevice(DEVICES.tablet) ? "" : "hidden"
        }`}
      >
        <IconContext.Provider value={{ className: `tablet-icon ${iconClass}` }}>
          <CgTouchpad />
        </IconContext.Provider>
      </div>
      <div
        className={`mobile-icon-container ${containerClass} ${
          doesAssetContainDevice(DEVICES.mobile) ? "" : "hidden"
        }`}
      >
        <IconContext.Provider value={{ className: `mobile-icon ${iconClass}` }}>
          <CgSmartphone />
        </IconContext.Provider>
      </div>
    </>
  );
};

DeviceIcons.defaultProps = {
  containerClass: undefined,
  iconClass: undefined,
};

DeviceIcons.propTypes = {
  doesAssetContainDevice: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  iconClass: PropTypes.string,
};

export default DeviceIcons;
