import getDamConfig from "./get-dam-config";

const environment = process.env.DEPLOYMENT_ENV || "prod";

const baseConfig = {
  aemAssetPickerPath: "/aem/assetpicker.html",
  aemAssetPickerBasePath:
    "/content/dam/loblaw-companies-limited/creative-assets",
  dynamicMediaHost: "https://s7d1.scene7.com",
};

const configs = {
  local: {
    aemDispatcherHost: "https://author-loblaw-dev1.adobecqms.net", // DEV
    aemAuthorHost: "https://author-loblaw-dev1.adobecqms.net",
    contentTypeId: "damAssetLocal",
    contentTypeName: "DAM Asset (Local)",
    appName: "LOCAL DAM Asset Selector",
    akamaiHost: "https://dis-dev1.assetful.loblaw.ca", // DEV
    dynamicMediaTenant: "loblawstage", // DEV
    metadataPath: "http://localhost:8080/dam/metadata",
    folderMetadataPath: "http://localhost:8080/dam/folder",
    webhookPath: "http://localhost:8080/dam/webhook",
  },
  dev: {
    aemDispatcherHost: "https://author-loblaw-stage.adobecqms.net",
    contentTypeId: "damAssetDev",
    contentTypeName: "DAM Asset (Dev)",
    appName: "DEV DAM Asset Selector",
    akamaiHost: "https://dis-stage.assetful.loblaw.ca",
    dynamicMediaTenant: "loblawstage",
    metadataPath: "https://contentful-dam.api.loblaw.ca/dam/metadata",
    folderMetadataPath: "https://contentful-dam.api.loblaw.ca/dam/folder",
    webhookPath: "contentful-dam.api.loblaw.ca/dam/webhook",
  },
  prod: {
    aemDispatcherHost: "https://author-loblaw-prod.adobecqms.net",
    contentTypeId: "damAsset",
    contentTypeName: "DAM Asset",
    appName: "DAM Asset Selector",
    akamaiHost: "https://dis-prod.assetful.loblaw.ca",
    dynamicMediaTenant: "loblaw",
    metadataPath: "https://contentful-dam.apiprod.loblaw.ca/dam/metadata",
    folderMetadataPath: "https://contentful-dam.apiprod.loblaw.ca/dam/folder",
    webhookPath: "contentful-dam.apiprod.loblaw.ca/dam/webhook",
  },
};

const Config = {
  ...baseConfig,
  ...configs[environment],
  damConfig: getDamConfig(configs[environment]),
};

export default Config;

export { environment };
